import { useLayoutEffect } from 'react'
import { style } from './reboot'

const CssBaseline = () => {
  useLayoutEffect(() => {
    const styleTag = document.createElement('style')
    styleTag.innerHTML = style
    styleTag.id = 'css-baseline'
    const head = document.getElementsByTagName('head')[0]
    const firstStyleTag = head.getElementsByTagName('style')[0]

    head.insertBefore(styleTag, firstStyleTag)

    return () => {
      const element = document.getElementById('css-baseline')
      if (element) {
        element.remove()
      }
    }
  })

  return null
}

export { CssBaseline }
