import { env } from '@rsmus/react-env-image'
import { Get, timeout } from '../services/http'

import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiRequestAsync } from '../redux/Actions/actions'
import { HttpRequestState } from '../models/core'
import {
  ApplicationClaim,
  SearchRequest,
  UserResults,
  UserState,
} from '../models'
import { useHttpErrors } from './useHttpError'
import { formatDate } from '../services'
// let controller = new AbortController()
export const useOrganizationUsers = () => {
  const dispatch = useDispatch()
  // const { signal } = controller
  const baseUrl = env('REACT_APP_ODATA_BASE_URL')

  const [requestStatus, setRequestStatus] = useState<HttpRequestState>('Idle')
  const [results, setResults] = useState<UserResults[]>([])
  const { handleNotOkResponse, handleRequestError } = useHttpErrors()

  const searchOrganizationUsers = useCallback(
    async (request: SearchRequest) => {
      if (!baseUrl) {
        return
      }

      const { organizationId, ...params } = request

      try {
        setRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Get(`${baseUrl}/api/organizations/${organizationId}/users`)
        )
        if (response.ok) {
          const data = await response.json()
          // setup extra column for action based on status and appClaims based on Apps
          data.forEach(
            (element: {
              actionStatus: string
              status: string
              claims?: ApplicationClaim[]
              appClaims: string
              acceptedEulas?: UserState[]
              flatAcceptedEulas: string
            }) => {
              element.actionStatus = element.status
              if (element.claims && element.claims.length > 0) {
                element.appClaims = element.claims
                  .map((ac: ApplicationClaim) => {
                    return (
                      ac.applicationDescription + ':' + ac.description + ' '
                    )
                  })
                  .join(',')
              } else {
                element.appClaims = 'No Roles Assigned'
              }
              if (element.acceptedEulas && element.acceptedEulas.length > 0) {
                element.flatAcceptedEulas = element.acceptedEulas
                  .map((us: UserState) => {
                    return us.name + ':' + formatDate(us.dateSigned) + ' '
                  })
                  .join(',')
              } else {
                element.flatAcceptedEulas = 'No Eulas Accepted'
              }
            }
          )
          setResults(data)
          setRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )
  const removeUsers = (userlist: string[]) => {
    let removedAUser: boolean = false
    userlist.forEach((userid) => {
      const position = results.findIndex((x) => x.id === userid)
      if (position > -1) {
        results.splice(position, 1)
        removedAUser = true
      }
    })
    if (removedAUser) {
      setResults([...results])
    }
  }
  const replaceUser = (userResults: UserResults) => {
    let replacedAUser: boolean = false
    const position = results.findIndex((x) => x.id === userResults.id)
    if (position > -1) {
      results.splice(position, 1, userResults)
      replacedAUser = true
    }
    if (replacedAUser) {
      setResults([...results])
    }
  }
  return {
    searchOrganizationUsers,
    removeUsers,
    replaceUser,
    requestStatus,
    results,
    setResults,
  }
}
