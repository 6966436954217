import { AppBar, Checkbox, makeStyles, Paper, Toolbar } from '@material-ui/core'
import {
  DataGrid,
  GridApi,
  GridCellParams,
  GridColDef,
} from '@material-ui/data-grid'
import React from 'react'
import { DownloadButton } from '../../core/components/Download'
import { UserApplicationViewModel } from '../../core/models'
import { TsaTheme } from '../../core/styles/tsaTheme'
const useStyles = makeStyles((theme: typeof TsaTheme) => ({
  root: {
    margin: theme.spacing(0, 0),
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  grow: {
    flexGrow: 1,
  },
  ManageUserHeading: {
    fontSize: 22,
  },
}))
export interface UserViewAppProps {
  userApps: UserApplicationViewModel[]
  userId: string
}
const UserViewApp = ({ userApps, userId }: UserViewAppProps) => {
  const classes = useStyles()
  var apiRef: GridApi | undefined = undefined
  // transform  data for grid
  const getColumDefinitions = () => {
    const columns: GridColDef[] = [
      { field: 'id', hide: true },
      {
        field: 'identifier',
        headerName: 'APPLICATION IDENTIFIER',
        width: 250,
      },
      {
        field: 'externalManaged',
        headerName: 'EXTERNAL MANAGED',
        width: 250,
        renderCell: (params: GridCellParams) => {
          const checked = params.value as boolean

          return (
            <Checkbox
              disabled={true}
              defaultChecked={checked}
              color='primary'
            />
          )
        },
      },
      {
        field: 'description',
        headerName: 'APPLICATION NAME',
        width: 340,
        renderCell: (params: GridCellParams) => {
          apiRef = params.api

          return <React.Fragment>{params.value}</React.Fragment>
        },
      },
      {
        field: 'roleDescriptions',
        headerName: 'ROLES',
        width: 600,
      },
    ]
    return columns
    // we want to watch search query and re run this
    // eslint-disable-next-line
  }
  const handleExportApplications = () => {
    if (apiRef) {
      apiRef.exportDataAsCsv()
    }
  }
  return (
    <Paper className={classes.root} variant='outlined'>
      <AppBar position='static'>
        <Toolbar variant='dense'>
          <div className={classes.ManageUserHeading}>
            Applications ({userApps.length}){' '}
          </div>
          <div className={classes.grow} />
          <DownloadButton
            toolTip={'Download Applications'}
            handleDownLoad={handleExportApplications}
          />
        </Toolbar>
      </AppBar>
      <div style={{ height: 'auto', width: '100%' }}>
        <DataGrid
          autoHeight={true}
          rowHeight={40}
          headerHeight={40}
          rows={userApps}
          columns={getColumDefinitions()}
          pageSize={100}
          density={'compact'}
        />
      </div>
    </Paper>
  )
}
export default UserViewApp
