import { env } from '@rsmus/react-env-image'
import { Get, Put, Delete, timeout } from '../services/http'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiRequestAsync } from '../redux/Actions/actions'
import {
  HttpRequestState,
  BrandedApplicationModel,
  BrandedApplicationEditModel,
} from '../models/core'
// import { ParentOu, UpdateOrganizationRequest } from '../models'
import { useHttpErrors } from './useHttpError'

export const useBrandedApplications = () => {
  const dispatch = useDispatch()
  const baseUrl = env('REACT_APP_ODATA_BASE_URL')
  const [baRequestStatus, setBaRequestStatus] =
    useState<HttpRequestState>('Idle')
  const [allBrandedApps, setAllBrandedApps] = useState<
    BrandedApplicationModel[]
  >([])
  const [allAvailableBrandedApps, setAllAvailableBrandedApps] = useState<
    BrandedApplicationModel[]
  >([])
  const [brandedApplicationEdit, setBrandedApplicationEdit] =
    useState<BrandedApplicationEditModel>()
  const { handleNotOkResponse, handleRequestError } = useHttpErrors()
  const getBrandedApplications = useCallback(
    async () => {
      if (!baseUrl) {
        return
      }

      try {
        setBaRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Get(`${baseUrl}/api/brandedapplications`)
        )
        if (response.ok) {
          const data = await response.json()
          //   data.forEach((brandapp: BrandedApplicationModel) => {
          //     brandapp.id = brandapp.identifier // just need id for the grid and detial edit
          //   })
          setAllBrandedApps(data)
          setBaRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setBaRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setBaRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )
  const getAvailableBrandedApplications = useCallback(
    async () => {
      if (!baseUrl) {
        return
      }

      try {
        setBaRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Get(`${baseUrl}/api/brandedapplications/availablebrandedapps`)
        )
        if (response.ok) {
          const data = await response.json()
          data.forEach((brandapp: BrandedApplicationModel) => {
            brandapp.id = brandapp.identifier // just need id for the grid and detial edit
          })
          setAllAvailableBrandedApps(data)
          setBaRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setBaRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setBaRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )
  const getBrandedApplicationEdit = useCallback(
    async (id: number) => {
      if (!baseUrl) {
        return
      }

      try {
        setBaRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Get(`${baseUrl}/api/brandedapplications/${id}`)
        )
        if (response.ok) {
          const data = await response.json()

          setBrandedApplicationEdit(data)
          setBaRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setBaRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setBaRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )
  const getUserAuthorizedBrandedApplications = useCallback(
    async (userId: string) => {
      if (!baseUrl) {
        return
      }

      try {
        setBaRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Get(`${baseUrl}/api/brandedapplications/${userId}/authorizedapps`)
        )
        if (response.ok) {
          const data = await response.json()
          //   data.forEach((brandapp: BrandedApplicationModel) => {
          //     brandapp.id = brandapp.identifier // just need id for the grid and detial edit
          //   })
          setAllBrandedApps(data)
          setBaRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setBaRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setBaRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )
  const deleteBrandedApplication = useCallback(
    async (id: string) => {
      if (!baseUrl) {
        return
      }

      try {
        setBaRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Delete(`${baseUrl}/api/brandedapplications/${id}`)
        )
        if (response.ok) {
          setBaRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setBaRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setBaRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )
  const saveBrandedApplicationEdit = useCallback(
    async (request: BrandedApplicationEditModel) => {
      if (!baseUrl) {
        return
      }

      try {
        setBaRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Put(`${baseUrl}/api/brandedapplications`, request)
        )
        if (response.ok) {
          const data = await response.json()
          setBrandedApplicationEdit(data)
          setBaRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setBaRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setBaRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )

  return {
    getAvailableBrandedApplications,
    getBrandedApplicationEdit,
    getBrandedApplications,
    getUserAuthorizedBrandedApplications,
    deleteBrandedApplication,
    saveBrandedApplicationEdit,
    setAllBrandedApps,
    baRequestStatus,
    brandedApplicationEdit,
    allBrandedApps,
    allAvailableBrandedApps,
  }
}
