import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useOrganizationApplications } from '../../core/hooks/useOrganizationApplications'
import { useRoles } from '../../core/hooks/useRoles'
import { useOrganizationUser } from '../../core/hooks/useOrganizationUser'
import { useOrganizationUsers } from '../../core/hooks/useOrganizationUsers'
import { OrganizationApplications } from './organizationApplications'
import OrganizationDetail from './organizationDetail'
import OrganizationUsers from './organizationUsers'
import { UpdateOrganizationRequest } from '../../core/models'
import { ensureNumber } from '../../core/services/guards'
//import { useSelector } from 'react-redux'
//import { AppState } from '../../core/redux/store'
const Organization = () => {
  const { id } = useParams<{ id: string }>()
  const { userid } = useParams<{ userid: string }>()
  const [usersToRemove, setUsersToRemove] = useState<string[]>([])
  const [organizationUserDirty, SetOrganizationUserDirty] =
    useState<boolean>(false)
  const { saveUserStatus } = useOrganizationUser()
  const { saveApplications } = useOrganizationApplications()
  const { isAdministratorOrSuperuser, getActiveUser } = useRoles()
  const {
    removeUsers,
    replaceUser,
    searchOrganizationUsers,
    results,
    requestStatus,
  } = useOrganizationUsers()
  // we need more definaiton her on when to use this.
  //
  // const { searchQuery } = useSelector((state: AppState) => state.appContainer)

  useEffect(() => {
    // we need  to modify end point to support filter..
    searchOrganizationUsers({
      organizationId: id,
    })
  }, [searchOrganizationUsers, id])

  const handleUserActiveChange = (userId: string, active: boolean) => {
    saveUserStatus(userId, id, active)
  }
  // will push and pop users to the list to be removed.
  const handleUserRemoveChange = (userId: string, remove: boolean) => {
    // find the user.
    const position = usersToRemove.indexOf(userId)
    if (position > -1) {
      if (!remove) {
        usersToRemove.splice(position, 1)
      }
    } else {
      if (remove) {
        usersToRemove.push(userId)
      }
    }
    setUsersToRemove(usersToRemove)
  }
  // we need to merge   from applicaiotns and user screen here and save.
  const saveApplicationsAndRemoveUsers = (
    request: UpdateOrganizationRequest
  ) => {
    const newRequest = {
      id: request.id,
      applications: request.applications,
      toRemove: usersToRemove,
    }
    // remove from the results which is used in  organizationUsers
    removeUsers(usersToRemove)
    SetOrganizationUserDirty(false)
    return saveApplications(newRequest)
  }
  const canEdit = isAdministratorOrSuperuser(ensureNumber(id))
  const canAddEditRsmUserfn = (): boolean => {
    const activeUser = getActiveUser()
    if (activeUser) {
      // must be internal  and elgible to edit.
      return !activeUser.isExternal && canEdit ? true : false
    } else {
      return false
    }
  }
  // we need to refersh the list of users becasue of upload happened
  const forceUserRefresh = () => {
    searchOrganizationUsers({
      organizationId: id,
    })
  }

  const canAddEditRsmUser = canAddEditRsmUserfn()

  return (
    <React.Fragment>
      <OrganizationDetail organizationId={id} />
      <OrganizationApplications
        organizationId={id}
        onSaveApplications={saveApplicationsAndRemoveUsers}
        canEdit={canEdit}
        externalDirty={organizationUserDirty}
      />
      <OrganizationUsers
        organizationId={id}
        onActiveChange={handleUserActiveChange}
        onRemoveChange={handleUserRemoveChange}
        canEdit={canEdit}
        canAddEditRsmUser={canAddEditRsmUser}
        results={results}
        requestStatus={requestStatus}
        forceUserRefresh={forceUserRefresh}
        setExternalDirty={SetOrganizationUserDirty}
        replaceUser={replaceUser}
        filterUserId={userid}
      />
    </React.Fragment>
  )
}

export default Organization
