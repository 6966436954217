import { env } from '@rsmus/react-env-image'
import { Get, Put, timeout } from '../services/http'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiRequestAsync } from '../redux/Actions/actions'
import { HttpRequestState, OrganizationApplication } from '../models/core'
import { ParentOu, UpdateOrganizationRequest } from '../models'
import { useHttpErrors } from './useHttpError'
// let controller = new AbortController()

export const useOrganizationApplications = () => {
  const dispatch = useDispatch()
  // const { signal } = controller

  const baseUrl = env('REACT_APP_ODATA_BASE_URL')

  const [requestStatus, setRequestStatus] = useState<HttpRequestState>('Idle')
  const [applications, setApplications] = useState<OrganizationApplication[]>(
    []
  )
  const { handleNotOkResponse, handleRequestError } = useHttpErrors()
  const getApplications = useCallback(
    async (organizationId: string, parentOu: ParentOu) => {
      if (!baseUrl) {
        return
      }

      try {
        setRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Get(
            `${baseUrl}/api/organizations/${organizationId}/applications/${parentOu}`
          )
        )
        if (response.ok) {
          const data = await response.json()
          setApplications(data)
          setRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )

  const saveApplications = useCallback(
    async (request: UpdateOrganizationRequest) => {
      if (!baseUrl) {
        return
      }

      try {
        setRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Put(`${baseUrl}/api/organizations`, request)
        )
        if (response.ok) {
          setRequestStatus('Success')
          dispatch(apiRequestAsync.success)
          getApplications(request.id, 'External')
        } else {
          setRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch, getApplications]
  )

  return { getApplications, saveApplications, requestStatus, applications }
}
