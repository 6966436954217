import React from 'react'
import { UserProfileModel } from '../../core/models'
export interface UserViewUserProps {
  user?: UserProfileModel
}
const UserViewUser = ({ user }: UserViewUserProps) => {
  return (
    <React.Fragment>
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          fontSize: '1.5em',
        }}
      >
        <div>Name: {user?.firstName + ' ' + user?.lastName}</div>
        <div>Email: {user?.email}</div>
        <div>Id: {user?.rsmUid}</div>
        <div>Status: {user?.status}</div>
        <div>Federated: {user?.rsmIsFederated === true ? 'Yes' : 'No'}</div>
      </div>
    </React.Fragment>
  )
}
export default UserViewUser
