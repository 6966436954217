import { CircularProgress, Typography } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import {
  GridCellParams,
  GridColDef,
  DataGrid,
  GridValueFormatterParams,
} from '@material-ui/data-grid'
import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useOrganizations } from '../../core/hooks/useOrganizations'
import { AppState } from '../../core/redux/store'
import Edit from '@material-ui/icons/Edit'
import { formatClientId } from '../../core/services/formatters'
import { setShowSearch } from '../../core/redux/Actions'
import { OrgActiveButton } from '../organizations/orgActiveButton'
const Dashboard = () => {
  const { getMyOrganizations, searchOrganizations, results, requestStatus } =
    useOrganizations()

  const { searchQuery } = useSelector((state: AppState) => state.appContainer)
  const dispatch = useDispatch()

  useEffect(() => {
    // on activate we want to show search.
    dispatch(setShowSearch(true))
    searchOrganizations({ query: searchQuery })
    // on  unmount we want to clear search show
    return () => {
      dispatch(setShowSearch(false))
    }
  }, [searchOrganizations, searchQuery, dispatch])

  useEffect(() => {
    if (searchQuery === undefined || searchQuery === '') {
      getMyOrganizations()
    }
  }, [getMyOrganizations, searchOrganizations, searchQuery])

  const getColumDefinitions = useMemo(() => {
    const columns: GridColDef[] = [
      {
        field: 'masterClientNumber',
        headerName: 'MASTER ID',
        width: 140,
        hide: true,
        valueFormatter: (params: GridValueFormatterParams) =>
          formatClientId(params.value as number),
      },
      {
        field: 'id',
        headerName: 'ORG ID',
        width: 200,
        hide: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          return formatClientId(params.value as number)
        },
      },
      {
        field: 'masterAndClientId',
        headerName: 'ID',
        width: 200,
      },

      {
        field: 'name',
        headerName: 'NAME',
        width: 640,
        renderCell: (params: GridCellParams) => {
          return (
            <React.Fragment>
              <NavLink
                to={`/organizations/${params.getValue('id')}?mode=edit`}
                id='profile-menu-item-engagements'
                exact={true}
              >
                <Edit fontSize='small' />
              </NavLink>
              <NavLink
                to={`/organizations/${params.getValue('id')}`}
                id='profile-menu-item-engagements'
                exact={true}
              >
                <span>{params.value}</span>
              </NavLink>
            </React.Fragment>
          )
        },
      },
      { field: 'officeName', headerName: 'OFFICE', width: 256 },
      {
        field: 'isLost',
        headerName: 'STATUS',
        width: 200,
        renderCell: (params: GridCellParams) => {
          const isLost = params.getValue('isLost') as boolean
          return <OrgActiveButton isLost={isLost} />
        },
      },
    ]
    return columns
    // we want to watch search query and re run this
    // eslint-disable-next-line
  }, [searchQuery])

  if (requestStatus === 'Failed') {
    return null
  }

  if (requestStatus !== 'Success') {
    return (
      <CircularProgress
        style={{
          color: green[500],
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: -12,
          marginLeft: -12,
        }}
      />
    )
  }
  // this will show a heading of my organizaiton or search critera and organiztions
  const heading =
    searchQuery === undefined || searchQuery === ''
      ? 'My Organizations'
      : `Organizations(${searchQuery})`

  return (
    <React.Fragment>
      <Typography variant='h3'>{heading}</Typography>
      <div style={{ height: 800, width: '100%' }}>
        <DataGrid
          headerHeight={40}
          rowHeight={40}
          rows={results}
          columns={getColumDefinitions}
          pageSize={25}
        />
      </div>
    </React.Fragment>
  )
}

export default Dashboard
