import { env } from '@rsmus/react-env-image'
import { Post, timeout } from '../services/http'

import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiRequestAsync } from '../redux/Actions/actions'
import { HttpRequestState, UserProfileModel } from '../models/core'
import { useHttpErrors } from './useHttpError'

export const useIdpUser = () => {
  const dispatch = useDispatch()
  // const { signal } = controller
  const baseUrl = env('REACT_APP_ODATA_BASE_URL')

  const [idpRequestStatus, setIdpRequestStatus] = useState<HttpRequestState>(
    'Idle'
  )
  const [userResults, setUserResults] = useState<UserProfileModel>()
  const { handleNotOkResponse, handleRequestError } = useHttpErrors()
  const reactivateUser = useCallback(
    async (userId: string) => {
      if (!baseUrl) {
        return
      }

      try {
        setIdpRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Post(`${baseUrl}/api/idp/user/${userId}/reactivate`)
        )
        if (response.ok) {
          setIdpRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setIdpRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setIdpRequestStatus('Failed')
        handleRequestError(error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )
  const resetPasswordForUser = useCallback(
    async (userId: string) => {
      if (!baseUrl) {
        return
      }

      try {
        setIdpRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Post(`${baseUrl}/api/idp/user/${userId}/resetpassword`)
        )
        if (response.ok) {
          const data = await response.json()
          setUserResults(data)
          setIdpRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setIdpRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setIdpRequestStatus('Failed')
        handleRequestError(error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )
  const unlockUser = useCallback(
    async (userId: string) => {
      if (!baseUrl) {
        return
      }

      try {
        setIdpRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Post(`${baseUrl}/api/idp/user/${userId}/unlock`)
        )
        if (response.ok) {
          const data = await response.json()
          setUserResults(data)
          setIdpRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setIdpRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setIdpRequestStatus('Failed')
        handleRequestError(error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )

  return {
    reactivateUser,
    resetPasswordForUser,
    unlockUser,
    idpRequestStatus,
    userResults,
    setUserResults,
  }
}
