import { IconButton, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'

import { DeleteForever } from '@material-ui/icons'
import { ConfirmDialog } from '../../core/components/Dialogs/ConfirmDialog'

export interface BrandedApplicationDeleteButtonProps {
  brandedApplicationId: string
  brandedApplicationName: string
  onDelete: (brandedApplicationId: string) => void
  canEdit: boolean
}

export const BrandedApplicationDeleteButton = ({
  brandedApplicationId,
  brandedApplicationName,
  onDelete,
  canEdit,
}: BrandedApplicationDeleteButtonProps) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const HandleConfirmOpen = () => {
    setConfirmDialogOpen(true)
  }
  const onOkClickHandle = () => {
    onDelete(brandedApplicationId)
    setConfirmDialogOpen(false)
  }
  const onCancelHandle = () => {
    // close the confirm dialog
    setConfirmDialogOpen(false)
  }

  const confirmDialogTitle = () => {
    return `Are you sure you want to delete branded application( ${brandedApplicationName} )`
  }

  // a button and dialog
  return (
    <React.Fragment>
      <Tooltip title='Delete Branded Application' placement='top-start'>
        <IconButton
          disabled={!canEdit}
          aria-label='Delete Branded Application'
          color='inherit'
          onClick={HandleConfirmOpen}
        >
          <DeleteForever color='primary' />
        </IconButton>
      </Tooltip>
      {confirmDialogOpen && (
        <ConfirmDialog
          title={confirmDialogTitle()}
          isOpen={confirmDialogOpen}
          onOkHandler={onOkClickHandle}
          onCancelHandler={onCancelHandle}
        />
      )}
    </React.Fragment>
  )
}
