import { env } from '@rsmus/react-env-image'
import { Get, Post, timeout } from '../services/http'

import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiRequestAsync, PushAlert } from '../redux/Actions/actions'
import { HttpRequestState } from '../models/core'
import {
  ApplicationUserAttributeViewModelRequest,
  AssignUserRequest,
  CreateUserRequest,
  SearchRequest,
  UserProfileModel,
  UserSearchResult,
} from '../models'
import { useHistory } from 'react-router-dom'
import { useHttpErrors } from './useHttpError'

// let controller = new AbortController()

export const useUsers = () => {
  const dispatch = useDispatch()
  // const { signal } = controller
  const baseUrl = env('REACT_APP_ODATA_BASE_URL')

  const [requestStatus, setRequestStatus] = useState<HttpRequestState>('Idle')
  const [results, setResults] = useState<UserSearchResult[]>([])
  const [findResults, setFindResults] = useState<UserProfileModel[]>([])
  const [user, setUser] = useState<UserProfileModel>()
  const [applicationAndUserAttributes, setApplicationAndUserAttributes] =
    useState<ApplicationUserAttributeViewModelRequest>({})
  const history = useHistory()
  const { handleNotOkResponse, handleRequestError } = useHttpErrors()
  const searchUsers = useCallback(
    async (request: SearchRequest) => {
      const { organizationId, context, ...params } = request

      if (!params.query) {
        setResults([])
        setRequestStatus('Idle')
        return
      }

      // TODO:  End point should not be associated with the organization, but rely on a search request that will allow for the organization and parentOu to be set
      try {
        setRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)

        const url = `${baseUrl}/api/organizations/${organizationId}/users/${context}?filter=${
          encodeURIComponent(params.query) || ''
        }`
        const response = await timeout(30000, Get(url))
        if (response.ok) {
          const data = await response.json()
          setResults(data)
          setRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )
  const findUser = useCallback(
    async (request: SearchRequest) => {
      const { context, ...params } = request

      if (!params.query) {
        setResults([])
        setRequestStatus('Idle')
        return
      }

      // TODO:  End point should not be associated with the organization, but rely on a search request that will allow for the organization and parentOu to be set
      try {
        setRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)

        //api/App/users?parentOu=1&filter=mike.trotman%40rsmus.com Example Call.
        const url = `${baseUrl}/api/app/users?parentOu=${context}&filter=${
          encodeURIComponent(params.query) || ''
        }`
        const response = await timeout(30000, Get(url))
        if (response.ok) {
          const data = await response.json()
          setFindResults(data)
          setRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )

  const getUser = useCallback(
    async (rsmUid: string) => {
      try {
        setRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)

        const url = `${baseUrl}/api/users/${rsmUid}`

        const response = await timeout(30000, Get(url))
        if (response.ok) {
          const data = await response.json()
          setUser(data)
          setRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )
  const getUserApplicationAndUserAttributes = useCallback(
    async (rsmUid: string) => {
      try {
        setRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)

        const url = `${baseUrl}/api/users/${rsmUid}/applicationuserattributes`

        const response = await timeout(30000, Get(url))
        if (response.ok) {
          // no content clear out state.
          if (response.status === 204) {
            setApplicationAndUserAttributes({})
          } else {
            const data = await response.json()
            setApplicationAndUserAttributes(data)
          }
          setRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )

  const assignUser = useCallback(
    async (request: AssignUserRequest) => {
      if (!baseUrl) {
        return
      }

      const { organizationId, ...rest } = request

      try {
        setRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Post(
            `${baseUrl}/api/organizations/${organizationId}/users/assign`,
            rest
          )
        )
        if (response.ok) {
          setRequestStatus('Success')
          dispatch(apiRequestAsync.success)
          // add message here. on sucess.
          dispatch(PushAlert(`Successfully Assigned User`))
        } else {
          setRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
        // go back to listing.
        history.goBack()
      } catch (error) {
        setRequestStatus('Failed')
        handleRequestError(error as Error)
        history.goBack()
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch, history]
  )

  const createUser = useCallback(
    async (request: CreateUserRequest) => {
      if (!baseUrl) {
        return
      }

      try {
        setRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Post(`${baseUrl}/api/users`, request)
        )
        if (response.ok) {
          setRequestStatus('Success')
          dispatch(apiRequestAsync.success)
          dispatch(PushAlert(`Sucessfully Created User ${request.email}`))
        } else {
          setRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
        history.goBack()
      } catch (error) {
        setRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch, history]
  )

  return {
    searchUsers,
    requestStatus,
    results,
    getUser,
    getUserApplicationAndUserAttributes,
    assignUser,
    createUser,
    user,
    applicationAndUserAttributes,
    findUser,
    findResults,
    setFindResults,
  }
}
