import React, { useEffect } from 'react'
import { Loader } from '../../core/components/Loader'
import {
  ApplicationUserAttributeViewModelRequest,
  UpdateUserRequest,
  UserProfileModel,
} from '../../core/models'
import UserForm, { UserFormDefinition } from './userForm'
import { useUsers } from '../../core/hooks/useUsers'
import { useParams } from 'react-router-dom'
import { ensureNumber } from '../../core/services/guards'

const UserAssign = () => {
  const { id: organizationId, userId } = useParams<{
    id: string
    userId: string
  }>()

  const {
    getUser,
    assignUser,
    getUserApplicationAndUserAttributes,
    applicationAndUserAttributes,
    user,
    requestStatus,
  } = useUsers()

  useEffect(() => {
    getUser(userId)
  }, [getUser, userId])
  // read up possible User attribute values  on hte assing we may need to present and modify also.
  useEffect(() => {
    getUserApplicationAndUserAttributes(userId)
  }, [getUserApplicationAndUserAttributes, userId])

  const handleAssingRequest = (request: UpdateUserRequest) => {
    const {
      organizationId,
      id: userId,
      applicationClaims,
      applicationAndUserAttributes,
    } = request
    const modifiedapplicationAndUserAttributes: ApplicationUserAttributeViewModelRequest =
      {}
    Object.keys(request.applicationClaims).forEach((k) => {
      const claims = request.applicationClaims[k]
      // only move to output if there are claims for the application.
      if (applicationAndUserAttributes[ensureNumber(k)] && claims.length > 0) {
        modifiedapplicationAndUserAttributes[ensureNumber(k)] =
          applicationAndUserAttributes[ensureNumber(k)]
      }
    })
    assignUser({
      organizationId,
      userId: userId || '',
      applicationClaims,
      applicationAndUserAttributes: { ...modifiedapplicationAndUserAttributes },
    })
  }

  const getFormValues = (user?: UserProfileModel) => {
    if (user) {
      const { lastName, firstName, title, email, rsmIsInternal } = user
      const defaultFormValues: UserFormDefinition = {
        lastName: lastName,
        firstName: firstName,
        email: email,
        title: title || '',
        parentOu: rsmIsInternal ? 'Internal' : 'External',
        sepRsmuid: '',
        applicationClaims: {},
        rsmEulaAccepted: false,
        applicationAndUserAttributes: applicationAndUserAttributes,
      }

      return defaultFormValues
    } else {
      const defaultFormValues: UserFormDefinition = {
        lastName: '',
        firstName: '',
        email: '',
        title: '',
        parentOu: 'Unknown',
        sepRsmuid: '',
        applicationClaims: {},
        rsmEulaAccepted: false,
        applicationAndUserAttributes: {},
      }

      return defaultFormValues
    }
  }

  const ready = requestStatus === 'Success' && user !== undefined
  if (requestStatus === 'Failed' || requestStatus === 'Unauthorized') {
    // we dont want a spinning if  the request fails just return else endless spinner.
    return null
  }
  return (
    <Loader ready={ready}>
      <UserForm
        user={getFormValues(user)}
        organizationId={organizationId}
        userId={userId}
        onSave={handleAssingRequest}
        canEdit={true}
        callerAction={'Assign'}
      />
    </Loader>
  )
}

export default UserAssign
