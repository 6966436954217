import { User } from 'oidc-client'
import { createReducer } from 'typesafe-actions'
import {
  Actions,
  globalSearchAction,
  setUser,
  AlertMessage,
  PopAlert,
  PushAlert,
  setShowSearch,
} from '../Actions'

export type AppContainerState = {
  user?: User
  searchQuery?: string
  alerts: AlertMessage[]
  showSearch?: boolean
}

export const appContainerReducer = createReducer<AppContainerState, Actions>({
  alerts: new Array<AlertMessage>(),
})
  .handleAction(setUser, (state, action) => ({
    ...state,
    user: action.payload,
  }))
  .handleAction(globalSearchAction, (state, action) => ({
    ...state,
    searchQuery: action.payload,
  }))
  .handleAction(PushAlert, (state, action) => {
    const pushAlerts = Object.assign([], state.alerts)
    if (action.payload) {
      pushAlerts.push(action.payload)
    }
    state = {
      ...state,
      alerts: pushAlerts,
    }
    return state
  })
  .handleAction(PopAlert, (state, action) => {
    const popAlerts = Object.assign([], state.alerts)
    const index = popAlerts.length - 1
    if (index >= 0) {
      popAlerts.splice(index, 1)
      state = {
        ...state,
        alerts: popAlerts,
      }
    }
    return state
  })
  .handleAction(setShowSearch, (state, action) => ({
    ...state,
    showSearch: action.payload,
  }))
