import React, { useEffect } from 'react'
import { useRoles } from '../../core/hooks/useRoles'
import { useOrganizationUser } from '../../core/hooks/useOrganizationUser'
import { Loader } from '../../core/components/Loader'
import {
  ApplicationClaim,
  ApplicationClaimsRequest,
  ApplicationUserAttributeViewModelRequest,
  UpdateUserRequest,
  User,
} from '../../core/models'
import UserForm, { UserFormDefinition } from './userForm'
import { useParams } from 'react-router-dom'
// import { setUser } from '../../core/redux/Actions'
import { ensureNumber } from '../../core/services/guards'
// import { last } from 'lodash'

const UserDetail = () => {
  const { id: organizationId, userId } = useParams<{
    id: string
    userId: string
  }>()

  const { getUser, saveUser, user, requestStatus, setUser } =
    useOrganizationUser()
  const { isAdministratorOrSuperuser, getActiveUser } = useRoles()
  const canEdit = isAdministratorOrSuperuser(ensureNumber(organizationId))

  useEffect(() => {
    getUser(organizationId, userId)
  }, [getUser, organizationId, userId])

  const transformApplicationClaims = (
    applicationClaims: ApplicationClaim[]
  ): ApplicationClaimsRequest => {
    const result: ApplicationClaimsRequest = {}

    applicationClaims.forEach((ac) => {
      const current = result[ac.applicationId]
      if (current) {
        current.push(ac.id)
      } else {
        result[ac.applicationId] = [ac.id]
      }
    })

    return result
  }
  const canEditUserfn = (user?: User): boolean => {
    if (canEdit) {
      const activeUser = getActiveUser()
      if (activeUser && user) {
        // if internal user and can edit  return true.
        if (!activeUser.isExternal) {
          return true
        } else {
          // external Users can only edit external users.
          if (user.isExternal) {
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }
    } else {
      return false
    }
  }
  const canEditUser = canEditUserfn(user)

  const getFormValues = (user?: User) => {
    if (user) {
      const {
        lastName,
        firstName,
        title,
        email,
        applicationClaims,
        isExternal,
        rsmEulaAccepted,
        applicationAndUserAttributes,
      } = user

      const defaultFormValues: UserFormDefinition = {
        lastName: lastName,
        firstName: firstName,
        email: email,
        title: title || '',
        rsmEulaAccepted: rsmEulaAccepted || false,
        parentOu: isExternal ? 'External' : 'Internal',
        applicationClaims: transformApplicationClaims(applicationClaims),
        applicationAndUserAttributes: applicationAndUserAttributes,
      }
      // Here we are dynamically adding Properties and values to the UserForm Defination
      applicationClaims.forEach((appClaim) => {
        if (applicationAndUserAttributes[appClaim.applicationId]) {
          // only getting the first one for now.
          const appUserAttributeModel =
            applicationAndUserAttributes[appClaim.applicationId][0]
          defaultFormValues[appUserAttributeModel.attributeName] =
            appUserAttributeModel.attributeValue
        }
      })

      return defaultFormValues
    } else {
      const defaultFormValues: UserFormDefinition = {
        lastName: '',
        firstName: '',
        email: '',
        title: '',
        parentOu: 'Unknown',
        sepRsmuid: '',
        applicationClaims: {},
        rsmEulaAccepted: false,
        applicationAndUserAttributes: {},
      }

      return defaultFormValues
    }
  }
  const wrappedSaveUser = (request: UpdateUserRequest) => {
    // we are goint to map user from the UpdateRequest
    if (user) {
      if (request.fields.email) {
        user.email = request.fields.email
      }
      if (request.fields.firstName) {
        user.firstName = request.fields.firstName
      }
      if (request.fields.lastName) {
        user.lastName = request.fields.lastName
      }
      if (request.fields.title) {
        user.title = request.fields.title
      }
      // We need to  map back to the user.application claims from the
      // request.application claims.  so the ui refreshs correctly
      const modifiedUserClaims: ApplicationClaim[] = []
      const modifiedapplicationAndUserAttributes: ApplicationUserAttributeViewModelRequest =
        {}
      Object.keys(request.applicationClaims).forEach((k) => {
        const claims = request.applicationClaims[k]
        // only move to output if there are claims for the application.
        if (
          request.applicationAndUserAttributes[ensureNumber(k)] &&
          claims.length > 0
        ) {
          modifiedapplicationAndUserAttributes[ensureNumber(k)] =
            request.applicationAndUserAttributes[ensureNumber(k)]
        }

        claims.forEach((claimId) => {
          const appClaim: ApplicationClaim = {
            applicationId: ensureNumber(k),
            id: claimId,
            claim: 'null',
            applicationName: 'null',
            applicationDescription: 'null',
            description: 'null',
          }
          modifiedUserClaims.push(appClaim)
        })
      })
      user.applicationClaims = [...modifiedUserClaims]
      // leave ui the same.
      user.applicationAndUserAttributes = {
        ...request.applicationAndUserAttributes,
      }
      //console.log(request.applicationClaims)
      setUser({ ...user })
      // only want Application Users Attributes if the app claims have claims.
      request.applicationAndUserAttributes = {
        ...modifiedapplicationAndUserAttributes,
      }
    }
    saveUser(request)
  }

  const ready = requestStatus === 'Success' && user !== undefined
  if (requestStatus === 'Failed' || requestStatus === 'Unauthorized') {
    // we dont want a spinning if  the request fails just return else endless spinner.
    return null
  }

  return (
    <Loader ready={ready}>
      <UserForm
        user={getFormValues(user)}
        organizationId={organizationId}
        userId={userId}
        onSave={(request) => wrappedSaveUser(request)}
        canEdit={canEditUser}
        callerAction='Edit'
      />
    </Loader>
  )
}

export default UserDetail
