import { env } from '@rsmus/react-env-image'
import { Get, timeout } from '../services/http'

import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiRequestAsync } from '../redux/Actions/actions'
import { HttpRequestState } from '../models/core'
import { useHttpErrors } from './useHttpError'

export const useEula = () => {
  const dispatch = useDispatch()
  // const { signal } = controller
  const baseUrl = env('REACT_APP_EULA_BASE_URL')

  const [requestStatus, setRequestStatus] = useState<HttpRequestState>('Idle')
  const [eula, setEula] = useState<string>()
  const { handleNotOkResponse, handleRequestError } = useHttpErrors()
  const getEula = useCallback(
    async (eulaId: string) => {
      if (!baseUrl) {
        return
      }

      try {
        setRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Get(`${baseUrl}/api/eulas/${eulaId}/content`)
        )
        if (response.ok) {
          const data = await response.text()
          setEula(data)
          setRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setRequestStatus('Failed')
        handleRequestError(error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )

  return { getEula, requestStatus, eula }
}
