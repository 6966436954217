import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import {
  Button,
  CardActions,
  FormGroup,
  Grid,
  LinearProgress,
} from '@material-ui/core'
import { TsaTheme } from '../../core/styles/tsaTheme'

import { green } from '@material-ui/core/colors'

import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  ApplicationClaimsRequest,
  ApplicationUserAttributeViewModelRequest,
  ParentOu,
  UpdateFields,
  UpdateUserRequest,
} from '../../core/models'
import { OrganizationApplicationClaims } from '../organizations/organizationApplicationClaims'
import { NavLink, useHistory } from 'react-router-dom'
import { formatClientId } from '../../core/services/formatters'

const useStyles = makeStyles((theme: typeof TsaTheme) => ({
  root: {
    padding: '2rem',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      minWidth: 275,
    },
    minWidth: 275,
  },
  formControl: {
    margin: theme.spacing(1),
    marginBottom: '2rem',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  errorText: {
    color: theme.palette.error.main,
  },
  title: {
    padding: theme.spacing(3, 1),
  },
  pos: {
    marginBottom: 12,
  },
  eula: {
    marginLeft: 12,
  },
  eulaLabel: {
    fontWeight: 600,
    marginRight: '.5rem',
  },
}))

export interface UserFormProps {
  userId?: string
  organizationId: string
  user: UserFormDefinition
  onSave: (request: UpdateUserRequest) => void
  canEdit: boolean
  callerAction: string
}

export interface UserFormDefinition {
  [key: string]:
    | string
    | ParentOu
    | ApplicationClaimsRequest
    | ApplicationUserAttributeViewModelRequest
    | boolean
    | undefined

  lastName: string
  firstName: string
  email: string
  title: string
  parentOu: ParentOu
  rsmEulaAccepted: boolean
  applicationClaims: ApplicationClaimsRequest
  applicationAndUserAttributes: ApplicationUserAttributeViewModelRequest
  //// this are dynamic fields that may be there or not.
  // I think Formik needs them ahead of time not sure.
  // sepRsmuid?: string
  // pchcRsmuid?: string
  // firmContext?: string
}

const formValidationSchema = Yup.object().shape({
  lastName: Yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long, Max 50 Characters.')
    .required('Required'),
  firstName: Yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long, Max 50 Characters.')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  title: Yup.string()
    .min(1, 'Too Short!')
    .max(100, 'Too Long, Max 100 Characters.'),
  // RSMTermedEmployee: Yup.boolean(),
  // sepRsmuid: Yup.string().when('RSMTermedEmployee', {
  //   is: true,
  //   then: Yup.string()
  //     .min(2, 'Too Short!')
  //     .max(20, 'Too Long, Max 20 Characters.')
  //     .required('Required'),
  // }),
})

var HeaderComponent = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.title}>
      <Grid item xs={12}>
        <Typography variant='h4' color='textPrimary' gutterBottom>
          {children}
        </Typography>
      </Grid>
    </Grid>
  )
}

const UserForm = ({
  user,
  userId,
  organizationId,
  onSave,
  canEdit,
  callerAction,
}: UserFormProps) => {
  const classes = useStyles()
  const history = useHistory()
  const GoBack = () => {
    history.goBack()
  }
  const isInternal = user.parentOu === 'Internal'
  const disableEditAddress = !canEdit || isInternal || callerAction === 'Assign'
  return (
    <Formik
      initialValues={user}
      validationSchema={formValidationSchema}
      // this is the   function for the save. Which  converts form value to the
      //UpdateUserRequest model
      onSubmit={(values, { setSubmitting }) => {
        if (!user) {
          return
        }

        const {
          applicationClaims,
          parentOu,
          applicationAndUserAttributes,
          ...others
        } = values
        // needed to filter other field values only to the ones wanted.
        const baseUserFieldNames: string[] = [
          'firstName',
          'lastName',
          'title',
          'email',
        ]
        const formFields = others as UpdateFields
        const fields: UpdateFields = {}
        // Get fields that have changed
        // applicationUserAttribute fields are in the object also.
        // we need to not include those so only inlcude base Field names.
        Object.keys(others).forEach((k) => {
          if (user[k] !== formFields[k] && baseUserFieldNames.includes(k)) {
            fields[k] = formFields[k]
          }
        })

        const request: UpdateUserRequest = {
          id: userId || '',
          organizationId,
          fields,
          applicationClaims,
          parentOu,
          applicationAndUserAttributes,
        }

        onSave(request)
      }}
    >
      {({
        submitForm,
        isSubmitting,
        errors,
        touched,
        values,
        dirty,
        setFieldValue,
        setFieldTouched,
      }) => {
        // Handle change of the applicaiont claims/roles.
        const handleOrganizationApplicationsChange = (
          allFieldValues: ApplicationClaimsRequest
        ) => {
          setFieldValue('applicationClaims', allFieldValues)
        }
        const handleApplicationUserAttributesChange = (
          allFieldValues: ApplicationUserAttributeViewModelRequest,
          fieldName: string,
          fieldValue: string
        ) => {
          setFieldValue('applicationAndUserAttributes', allFieldValues)
          setFieldTouched(fieldName, true)
          setFieldValue(fieldName, fieldValue, true)
          //setFieldTouched(fieldName,true)
          // if (!fieldValue) {
          //   //setFieldError(fieldName, 'This Is required if the App is selected')
          //   errors[fieldName] = 'This Is required if the App is selected'
          //   //setErrors({errors[fieldName],This is an error})
          // } else {
          //   setFieldError(fieldName, undefined)
          // }
        }

        return (
          <Card className={classes.root} variant='outlined'>
            <CardContent>
              <Form>
                <FormGroup>
                  <HeaderComponent>
                    {`${user?.firstName} ${user?.lastName}`} Profile
                  </HeaderComponent>
                  <Field
                    component={TextField}
                    disabled={disableEditAddress}
                    variant='outlined'
                    name='lastName'
                    label='Last Name'
                    autoComplete='off'
                    helperText={touched.lastName ? errors.lastName : ''}
                    error={touched.lastName && Boolean(errors.lastName)}
                  />
                  <Field
                    component={TextField}
                    disabled={disableEditAddress}
                    variant='outlined'
                    name='firstName'
                    label='First Name'
                    autoComplete='off'
                    helperText={touched.firstName ? errors.firstName : ''}
                    error={touched.firstName && Boolean(errors.firstName)}
                  />
                  <Field
                    component={TextField}
                    disabled={disableEditAddress}
                    variant='outlined'
                    name='title'
                    label='Title'
                    autoComplete='off'
                    helperText={touched.title ? errors.title : ''}
                    error={touched.title && Boolean(errors.title)}
                  />

                  <Field
                    component={TextField}
                    disabled={disableEditAddress}
                    variant='outlined'
                    name='email'
                    label='Email'
                    autoComplete='off'
                    helperText={touched.email ? errors.email : ''}
                    error={touched.email && Boolean(errors.email)}
                  />
                  {/* <Field
                    component={TextField}
                    disabled={disableEditAddress}
                    variant='outlined'
                    name='sepRsmuid'
                    label='sepRsmuid'
                    autoComplete='off'
                    helperText={touched.sepRsmuid ? errors.sepRsmuid : ''}
                    error={touched.email && Boolean(errors.email)}
                  /> */}
                  {!isInternal && (
                    <>
                      <div className={classes.eula}>
                        <label className={classes.eulaLabel}>
                          RSM EULA Accepted:{' '}
                        </label>
                        <span>
                          {user.rsmEulaAccepted === true ? 'Yes' : 'No'}
                        </span>
                      </div>
                      <div className={classes.eula}>
                        {user.rsmEulaAccepted && (
                          <NavLink
                            to={`/eula/1`}
                            id='view-eula'
                            // target='_blank'
                          >
                            View EULA
                          </NavLink>
                        )}
                      </div>
                    </>
                  )}

                  {isSubmitting && <LinearProgress />}
                </FormGroup>
                <FormGroup>
                  <HeaderComponent>
                    {'Application Claims For Organization'}{' '}
                    {formatClientId(organizationId)}{' '}
                  </HeaderComponent>
                  <OrganizationApplicationClaims
                    organizationId={organizationId}
                    parentOu={values.parentOu}
                    touched={touched}
                    errors={errors}
                    value={values.applicationClaims}
                    onChange={handleOrganizationApplicationsChange}
                    canEdit={canEdit}
                    applicationAndUserAttributes={
                      values.applicationAndUserAttributes
                    }
                    onChangeAppUserAttribute={
                      handleApplicationUserAttributesChange
                    }
                    values={values}
                  />
                </FormGroup>
              </Form>
            </CardContent>
            <CardActions>
              <Button
                variant='contained'
                color='primary'
                size='large'
                onClick={GoBack}
              >
                Back
              </Button>
              {canEdit && (
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  disabled={isSubmitting || !dirty}
                  onClick={submitForm}
                >
                  Save
                </Button>
              )}
            </CardActions>
          </Card>
        )
      }}
    </Formik>
  )
}

export default UserForm
