import { ThemeProvider } from '@material-ui/core'
//import { CssBaseline } from '@material-ui/core'
import { CssBaseline } from './core/components/CssBaseline'
//import { CssBaseline } from '@rsmus/react-ui'
import { env } from '@rsmus/react-env-image'
import React from 'react'
// import { Provider } from 'react-redux'
import { TsaTheme } from './core/styles/tsaTheme'
import { Eula } from '@rsmus/rsm-eula'
import { OidcIdentity, getAccessToken } from '@rsmus/react-auth'
import { IsAuthenticated } from './core/routes'
import { Route, Switch, useHistory } from 'react-router-dom'
import AppContainer, { AppContainerRoute } from './core/components/AppContainer'
import { Provider } from 'react-redux'
import { store } from './core/redux/store'
import Dashboard from './scenes/dashboard/dashboard'
import { Apps, GroupAdd, BrandingWatermark } from '@material-ui/icons'
import BusinessIcon from '@material-ui/icons/Business'
import SearchIcon from '@material-ui/icons/Search'
// import AppSettingsAltIcon from '@material-ui/icons/AppSettingsAlt';

// import Apps from '@material-ui/icons/SettingsApplications'
import Organization from './scenes/organizations/organization'
import Applications from './scenes/applications/applications'
import UserDetail from './scenes/users/userDetail'
import UserAssign from './scenes/users/userAssign'
import UserCreate from './scenes/users/userCreate'
import ShowEula from './scenes/eula/showEula'
import BulkUploadUsers from './scenes/users/bulkUploadUsers'
import UserView from './scenes/users/userView'
import BrandedApplications from './scenes/brandedApplications/brandedApplications'
import BrandedApplication from './scenes/brandedApplications/brandedApplication'

//import SearchUserIcon from './core/components/Icons/searchUserIcon'
// const silentRefreshUri = window.location.origin + '/silent-refresh.html'// dont need any more
const getToken = async (): Promise<string> => {
  const token = await getAccessToken()
  return token || ''
}

const routes: AppContainerRoute[] = [
  {
    name: 'Applications',
    icon: () => <Apps fontSize={'large'} />,
    path: '/applications',
  },
  {
    name: 'Branded Applications',
    icon: () => <Apps fontSize={'large'} color='primary' />,
    path: '/brandedapplications',
  },
  {
    name: 'Organizations',
    icon: () => <BusinessIcon fontSize={'large'} />,
    path: '/organizations',
  },
  {
    name: 'Bulk Upload Users',
    icon: () => <GroupAdd fontSize={'large'} />,
    path: '/bulkuploadusers',
  },
  {
    name: 'Search Users',
    icon: () => <SearchIcon fontSize={'large'} />,
    // icon: () => <SearchUserIcon />,
    path: '/userfindview',
  },
]

const App = () => {
  const myhistory = useHistory()
  // function to handle the identity redirect callback for deeplinks.
  const handleRedirectCallback = (url: string) => {
    try {
      const fullUrl = new URL(url)
      if (fullUrl.pathname !== myhistory.location.pathname) {
        myhistory.push(fullUrl.pathname)
      } else {
        window.history.replaceState({}, '', url)
      }
    } catch (err) {
      console.log('handleRedirect error here')
    }
  }
  // Uncomment this to test a quick refresh token flow.
  // Every 15 seconds the refresh token flow will happen.
  // const quickRefreshAccessTokenExpiringNotificationTimeInSeconds = 7080 // 2 hours(7200= 60 mins * 60 seconds*2) - 120 seconds
  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={TsaTheme}>
        <Provider store={store}>
          <div>
            <OidcIdentity
              authority={env('REACT_APP_PING_BASE_END_POINT')}
              client_id={env('REACT_APP_PING_APP_ID')}
              redirect_uri={window.location.origin}
              //silentRefreshUri={silentRefreshUri}  // no longer needed
              // default to 60 second before expire  we set to an hour before expired
              // not sure needed. but can remove.
              // automaticSilentRenew={true}
              accessTokenExpiringNotificationTimeInSeconds={env(
                'REACT_APP_OidcSilentRefreshOffsetTimeInSeconds'
              )}
              // uncomment this to test quick refresh token flow. every 15 seconds
              // accessTokenExpiringNotificationTimeInSeconds={
              //   quickRefreshAccessTokenExpiringNotificationTimeInSeconds
              // }
              rolesAuthority={env('REACT_APP_AUTHORIZATION_BASE_URL')}
              autoLogin={true}
              // redirect to logout urls on log out.
              // if not set to true will just redirest to app and rechallenge for creds.
              redirectAfterLogout={true}
              postLogoutRedirectUri={'https://rsmus.com'}
              //This an optional callback to handle the deep link/callback
              onDeepLinkRedirectCallback={handleRedirectCallback}
            >
              <IsAuthenticated>
                <Eula
                  host={env('REACT_APP_EULA_BASE_URL')}
                  tokenFactory={getToken}
                >
                  <AppContainer routes={routes}>
                    <Switch>
                      <Route
                        exact={true}
                        path='/dashboard'
                        component={Dashboard}
                      />
                      <Route
                        exact={true}
                        path='/eula/:id'
                        component={ShowEula}
                      />
                      <Route
                        exact={true}
                        path='/organizations'
                        component={Dashboard}
                      />
                      <Route
                        exact={true}
                        path='/organizations/:id'
                        component={Organization}
                      />
                      <Route
                        exact={true}
                        path='/organizations/:id/:userid'
                        component={Organization}
                      />
                      <Route
                        exact={true}
                        path='/organizations/:id/users/create'
                        component={UserCreate}
                      />
                      <Route
                        exact={true}
                        path='/organizations/:id/users/assign/:userId'
                        component={UserAssign}
                      />
                      <Route
                        exact={true}
                        path='/organizations/:id/users/:userId'
                        component={UserDetail}
                      />
                      <Route
                        exact={true}
                        path='/applications'
                        component={Applications}
                      />
                      <Route
                        exact={true}
                        path='/applications/:id'
                        component={Applications}
                      />
                      <Route
                        exact={true}
                        path='/bulkuploadusers'
                        component={BulkUploadUsers}
                      />
                      <Route
                        exact={true}
                        path='/userfindview'
                        component={UserView}
                      />
                      <Route
                        exact={true}
                        path='/userfindview/:id'
                        component={UserView}
                      />
                      <Route
                        exact={true}
                        path='/brandedapplications'
                        component={BrandedApplications}
                      />
                      <Route
                        exact={true}
                        path='/brandedapplication/edit/:id'
                        component={BrandedApplication}
                      />
                      <Route exact={true} path='/' component={Dashboard} />

                      {/* <Redirect exact from='/' to='/dashboard' /> */}
                    </Switch>
                  </AppContainer>
                </Eula>
              </IsAuthenticated>
            </OidcIdentity>
          </div>
        </Provider>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default App
