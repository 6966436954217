import React from 'react'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { TsaTheme } from '../../styles/tsaTheme'

const styles = (theme: typeof TsaTheme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    fontWeight: 600,
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'center', // center buttons on the bottom
  },
}))(MuiDialogActions)
export interface ConfirmDialogProps {
  title: string
  isOpen: boolean
  onOkHandler: () => void
  onCancelHandler: () => void
}
export const ConfirmDialog = ({
  title,
  isOpen,
  onOkHandler,
  onCancelHandler,
}: ConfirmDialogProps) => {
  const handleOk = () => {
    onOkHandler()
  }
  const handleClose = () => {
    onCancelHandler()
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='Confirm Dialog'
        open={isOpen}
      >
        <DialogTitle id='Confirm Dialog' onClose={handleClose}>
          Confirm Action
        </DialogTitle>
        <DialogContent color='textPrimary'>{title}</DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleClose} color='default'>
            Cancel
          </Button>
          <Button
            autoFocus
            variant='contained'
            onClick={handleOk}
            color='primary'
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
