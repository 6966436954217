// tslint:disable:no-any

export function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined
}

export function isUndefined<T>(value: T | undefined): value is undefined {
  return value === undefined
}

export function isNull(value: any): value is null {
  return value === null
}

export function isFunction(value: any): value is () => void {
  return typeof value === 'function'
}

/**
 * TypeScript type guard for a string.
 */
export function isString(value: any): value is string {
  return typeof value === 'string'
}

export function isStringOrUndefined(value: any): value is string | undefined {
  return typeof value !== void 0 || typeof value === 'string'
}

/**
 * TypeScript type guard for a number.
 */
export function isNumber(value: any): value is number {
  return typeof value === 'number'
}

/**
 * TypeScript type guard for an object.
 */
export function isObject(value: any): value is object {
  return typeof value === 'object'
}

/**
 * TypeScript type guard for an array of strings.
 * Checks first to see if this is an array. If the array is empty this function
 * will return true. If the array contains any elements the first one
 * is checked to see if it is a string.
 */
export function isArrayOfStrings(value: any): value is string[] {
  if (!Array.isArray(value)) {
    return false
  }
  if (value.length === 0) {
    return true
  }
  return isString(value[0])
}

/**
 * TypeScript type guard for an array of strings or undefined
 * Checks first to see if this is an array. If the array is empty this function
 * will return true. If the array contains any elements the first one
 * is checked to see if it is a string.
 */
export function isArrayOfStringsorUndefined(
  value: any
): value is string[] | undefined {
  if (Array.isArray(value) || value === undefined) {
    return true
  }

  if (value !== undefined && value.length === 0) {
    return true
  }

  return false
}

/**
 * TypeScript type guard for an array of number.
 * Checks first to see if this is an array. If the array is empty this function
 * will return true. If the array contains any elements the first one
 * is checked to see if it is a number.
 */
export function isArrayOfNumbers(value: any): value is number[] {
  if (!Array.isArray(value)) {
    return false
  }
  if (value.length === 0) {
    return true
  }
  return isNumber(value[0])
}

/**
 * Ensure that the specified value is a number. If the value is a string it
 * will be parsed and the value returned.
 * @param value
 */
export function ensureNumber(value: string | number): number {
  if (isString(value)) {
    return parseInt(value, 10)
  }
  return value
}
