import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useEula } from '../../core/hooks/useEula'
import { Loader } from '../../core/components/Loader'
import { Card, CardContent } from '@material-ui/core'
import rsmLogo from './rsmLogo.png'
const ShowEula = () => {
  const { id } = useParams<{ id: string }>()
  const { getEula, eula, requestStatus } = useEula()
  // read up the eula
  useEffect(() => {
    getEula(id)
  }, [getEula, id])
  const ready = requestStatus === 'Success'
  const createContent = () => {
    return { __html: eula as string }
  }
  return (
    <Loader ready={ready}>
      <Card>
        <CardContent>
          <div style={{ width: '100%' }}>
            <img src={rsmLogo} alt='RSM' style={{ marginLeft: '45%' }} />
          </div>
          <div>
            <div dangerouslySetInnerHTML={createContent()} />
          </div>
        </CardContent>
      </Card>
    </Loader>
  )
}
export default ShowEula
