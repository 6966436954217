import { CircularProgress } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { OidcIdentityContext } from '@rsmus/react-auth'
import React, { ReactNode, useContext } from 'react'
import { NotAuthorized } from '../components'

type IsAuthenticatedProps = {
  children: ReactNode
}

export const IsAuthenticated = ({ children }: IsAuthenticatedProps) => {
  const { loadingRoles } = useContext(OidcIdentityContext)
  const auth = React.useContext(OidcIdentityContext)

  if (loadingRoles) {
    return (
      <CircularProgress
        style={{
          color: green[500],
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: -12,
          marginLeft: -12,
        }}
      />
    )
  }

  if (!auth.user) {
    return <NotAuthorized />
  }

  return <>{children}</>
}
