import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { CardHeader, Grid } from '@material-ui/core'
import { TsaTheme } from '../../core/styles/tsaTheme'
import { useOrganization } from '../../core/hooks'
import { Loader } from '../../core/components/Loader'
import { formatMasterAndClientId } from '../../core/services'
import { OrgActiveButton } from '../organizations/orgActiveButton'
const useStyles = makeStyles((theme: typeof TsaTheme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    padding: theme.spacing(1, 2),
  },
  subTitle: {
    width: '100px',
    padding: 0,
    margin: 0,
  },
  pos: {
    marginBottom: 12,
  },
  active: {
    color: 'green',
  },
  inactive: {
    color: 'red',
  },
}))

export interface OrganizationDetailProps {
  organizationId: string
}

const OrganizationDetail = ({ organizationId }: OrganizationDetailProps) => {
  const classes = useStyles()
  const { getOrganization, organization, requestStatus } = useOrganization()

  useEffect(() => {
    getOrganization(organizationId)
  }, [getOrganization, organizationId])

  var HeaderComponent = () => {
    return (
      <Grid container className={classes.title}>
        <Grid item xs={12}>
          <Typography variant='h4' color='textPrimary' gutterBottom>
            {organization?.name}
          </Typography>
          <OrgActiveButton isLost={organization?.isLost} />
        </Grid>
      </Grid>
    )
  }

  const ready = requestStatus === 'Success'
  if (requestStatus === 'Failed' || requestStatus === 'Unauthorized') {
    // we dont want a spinning if  the request fails just return else endless spinner.
    return null
  }
  return (
    <Card className={classes.root} variant='outlined'>
      <CardHeader component={HeaderComponent} />
      <CardContent>
        <Loader ready={ready}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Typography variant='h6' component='h2'>
                Organization Id
              </Typography>
              <Typography className={classes.pos} color='textSecondary'>
                {formatMasterAndClientId(
                  organization?.masterClientNumber,
                  organization?.id
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h6' component='h2'>
                Office Name:
              </Typography>
              <Typography className={classes.pos} color='textSecondary'>
                {organization?.officeName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h6' component='h2'>
                Office #:
              </Typography>
              <Typography className={classes.pos} color='textSecondary'>
                {organization?.officeNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h6' component='h2'>
                Region:
              </Typography>
              <Typography className={classes.pos} color='textSecondary'>
                {organization?.region}
              </Typography>
            </Grid>
          </Grid>
        </Loader>
      </CardContent>
    </Card>
  )
}

export default OrganizationDetail
