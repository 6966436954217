import React, { useEffect, useState } from 'react'
import { fade, Theme, withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import ErrorSharpIcon from '@material-ui/icons/ErrorSharp'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogContentText from '@material-ui/core/DialogContentText'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { green } from '@material-ui/core/colors'
import {
  Toolbar,
  CircularProgress,
  // InputBase,
  // Paper,
  // FormControl,
  // FormControlLabel,
  // Radio,
  // RadioGroup,
  // List,
  // ListItem,
  // ListItemText,
  // ListItemSecondaryAction,
  // ListItemIcon,
  Tooltip,
  Button,
} from '@material-ui/core'
import { GroupAdd } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'
import { TsaTheme } from '../../core/styles/tsaTheme'
import { useFileDownload } from '../../core/hooks/useFileDownload'
import UploadDialog from '../../core/components/FileUpload/UploadDialog'
import SummaryDialog from '../../core/components/FileUpload/UploadSummaryDialog'
import { useFileUpload } from '../../core/hooks/useFileUpload'
import { HRFeedButton } from './hrFeedButton'
const acceptedFileTypes = ['.xltx', '.xltm', '.xlsx', '.xls', '.xlsm']
const useStyles = makeStyles((theme: typeof TsaTheme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addIcon: {
    padding: theme.spacing(0, 2),
    // height: '100%',
    // position: 'absolute',
    // pointerEvents: 'none',
    // display: 'flex',
    alignItems: 'right',
    justifyContent: 'right',
  },
  IconError: {
    color: 'red',
    height: '4rem',
    width: '4rem',
    display: 'block',
    margin: '0 auto',
  },
  headerText: {
    textAlign: 'center',
    display: 'block',
    margin: '0 auto',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  mytoolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  button: {
    marginRight: '5px',
  },
  buttonProgress: {
    color: green[500],
    marginLeft: 10,
  },
}))

export interface DialogTitleProps {
  id: string
  children: React.ReactNode
  onClose: () => void
}

export const DialogTitle = ({
  children,
  onClose,
  ...other
}: DialogTitleProps) => {
  const classes = useStyles()

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography color='textPrimary' variant='h6'>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

export const DialogContentText = withStyles((theme: Theme) => ({
  root: {
    fontSize: '1.2rem',
    fontWeight: 700,
  },
}))(MuiDialogContentText)

export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)
export interface BulkUsersButtonProps {
  organizationId: string
  canAddEditRsmUser: boolean
  forceUserRefresh?: () => void // callback to refresh list of users on complete of upload
}
export const BulkUsersButton = ({
  organizationId,
  canAddEditRsmUser,
  forceUserRefresh,
}: BulkUsersButtonProps) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>()
  const [summaryDialogOpen, setSummaryDialogOpen] = useState<boolean>(false)
  // eslint-disable-next-line
  const [files, setFiles] = useState<File[]>([])
  const { downloadFile } = useFileDownload()
  const { uploadFile, uploadRequestStatus, error, results } = useFileUpload()
  const [needsRefreshUsers, setNeedsRefreshUsers] = useState<boolean>(false)
  const handleBeginUpload = () => {
    setUploadDialogOpen(true)
  }
  const handleDismissUpload = () => {
    setUploadDialogOpen(false)
    setSummaryDialogOpen(false)
  }
  const handleSubmitFiles = (droppedFiles: File[]) => {
    // close upload dialog
    setUploadDialogOpen(false)
    setFiles(droppedFiles)
    uploadFile(organizationId, droppedFiles)
    //setSummaryDialogOpen(true)
  }
  const handleReUpload = () => {
    setSummaryDialogOpen(false)

    setUploadDialogOpen(true)
  }

  useEffect(() => {
    if (uploadRequestStatus === 'Success') {
      setUploadDialogOpen(false)
      setSummaryDialogOpen(true)
      setNeedsRefreshUsers(true) // Upload was sucessful we need to refresh users
    } else if (uploadRequestStatus === 'Failed') {
      setUploadDialogOpen(false)
      setSummaryDialogOpen(true)
    }
  }, [uploadRequestStatus]) // eslint-disable-line

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    if (forceUserRefresh && needsRefreshUsers) {
      forceUserRefresh()
    }
  }
  const handleDownload = () => {
    downloadFile()
    // close the window
    setOpen(false)
  }

  const inProgress = uploadRequestStatus === 'InProgress'
  // used  to pass tothe hr feed button so it will do a refresh if sucessfull upload on close
  const setForceRefresh = () => {
    setNeedsRefreshUsers(true)
  }
  const getSummaryHeaderError = () => {
    return (
      <div>
        <ErrorSharpIcon className={classes.IconError} />
        <p className={classes.headerText}>{'Users'}</p>
      </div>
    )
  }
  //only this organization will the button show up on.
  const showUploadHrFeed = organizationId === '7780057'

  return (
    <React.Fragment>
      <Tooltip title='Bulk Create or Assign Users' placement='top-start'>
        <IconButton
          aria-label='Import Users'
          color='inherit'
          onClick={handleClickOpen}
        >
          <GroupAdd />
        </IconButton>
      </Tooltip>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          Bulk Upload Users
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText color='textPrimary'>
            You cannot make bulk edits once users have been added, so please
            review the data's accuracy before uploading.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Toolbar className={classes.mytoolbar}>
            <Button
              onClick={handleDownload}
              variant='contained'
              color='primary'
              className={classes.button}
            >
              DOWNLOAD TEMPLATE
            </Button>

            <Button
              onClick={handleBeginUpload}
              variant='contained'
              color='primary'
              className={classes.button}
            >
              UPLOAD USERS
            </Button>
            {showUploadHrFeed && (
              <HRFeedButton
                forceUserRefresh={setForceRefresh}
                canAddEditRsmUser={canAddEditRsmUser}
                organizationId={organizationId}
              ></HRFeedButton>
            )}
            {inProgress && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Toolbar>
        </DialogActions>
      </Dialog>
      {uploadDialogOpen && (
        <UploadDialog
          open={uploadDialogOpen}
          acceptedFiles={acceptedFileTypes}
          maxFiles={1}
          onUpload={handleSubmitFiles}
          onDissmiss={handleDismissUpload}
        />
      )}

      {uploadRequestStatus === 'Failed' && (
        <SummaryDialog
          open={summaryDialogOpen}
          summaryHeader={getSummaryHeaderError()}
          onDissmiss={handleDismissUpload}
          onReUpload={handleReUpload}
          results={results}
          error={error}
        />
      )}
      {uploadRequestStatus === 'Success' && (
        <SummaryDialog
          context={'Users'}
          open={summaryDialogOpen}
          onDissmiss={handleDismissUpload}
          onReUpload={handleReUpload}
          results={results}
        />
      )}
    </React.Fragment>
  )
}
