import { IconButton, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { Add } from '@material-ui/icons'
import { useBrandedApplications } from '../../core/hooks/useBrandedApplications'
import { ApplicationSelectDialog } from './ApplicationSelectDialog'
import {
  BrandedApplicationEditModel,
  BrandedApplicationModel,
} from '../../core/models'
export interface BrandedApplicationAddButtonProps {
  onAdd: (brandedApplication?: BrandedApplicationEditModel) => void
  canEdit: boolean
}

export const BrandedApplicationAddButton = ({
  onAdd,
  canEdit,
}: BrandedApplicationAddButtonProps) => {
  const {
    getAvailableBrandedApplications,
    saveBrandedApplicationEdit,
    allAvailableBrandedApps,
    brandedApplicationEdit,
  } = useBrandedApplications()
  const [applicationSelectDialogOpen, setApplicationSelectDialogOpen] =
    useState(false)

  /// will read up all branded applications
  useEffect(() => {
    getAvailableBrandedApplications()
  }, [getAvailableBrandedApplications, brandedApplicationEdit])

  const HandleApplicationSelectOpen = () => {
    setApplicationSelectDialogOpen(true)
  }

  useEffect(() => {
    if (brandedApplicationEdit) {
      onAdd(brandedApplicationEdit)
    }
  }, [brandedApplicationEdit, onAdd])

  const onAddHandler = (brandedApp: BrandedApplicationModel) => {
    brandedApp.id = '0' // force a new one.
    const editModel: BrandedApplicationEditModel = {
      ...brandedApp,
      overrideLaunchUri: '',
      internalName: brandedApp.name,
      overrideLogoUri: '',
      overrideName: '',
    }
    // will update the brandedApplicationEdit on complete
    saveBrandedApplicationEdit(editModel)
      .then((res) => {
        setApplicationSelectDialogOpen(false) // clear the  select dialog
      })
      .catch((error) => {
        alert(error)
      })
  }
  const onCancelHandle = () => {
    // close the ApplicationSelect dialog
    setApplicationSelectDialogOpen(false)
  }

  const applicationSelectDialogTitle = () => {
    return 'Select An Application to Add'
  }

  // a button and dialog
  return (
    <React.Fragment>
      <Tooltip title='Add Branded Application' placement='top-start'>
        <IconButton
          disabled={!canEdit}
          aria-label='Add Branded Application'
          color='inherit'
          onClick={HandleApplicationSelectOpen}
        >
          <Add color='inherit' />{' '}
          {/*/AddModerator would  be better look into installing @mui/material-icon https://www.npmjs.com/package/@mui/icons-material*/}
        </IconButton>
      </Tooltip>
      {applicationSelectDialogOpen && (
        <ApplicationSelectDialog
          title={applicationSelectDialogTitle()}
          isOpen={applicationSelectDialogOpen}
          onCancelHandler={onCancelHandle}
          onAddHandler={onAddHandler}
          applications={allAvailableBrandedApps}
          canEdit={canEdit}
        />
      )}
    </React.Fragment>
  )
}
