import React, { useCallback, useEffect, useState } from 'react'
import { createStyles, fade, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import { useDispatch, useSelector } from 'react-redux'
import { globalSearchAction } from './actions'
import { debounce } from 'lodash'
import { AppState } from '../../redux/store'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  })
)

const GlobalSearch = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [localSearchQuery, setLocalSearchQuery] = useState<string>('')
  // eslint-disable-next-line
  const debouncedDispacthChangeQuery = useCallback(
    debounce((value: string) => dispatch(globalSearchAction(value)), 500),
    []
  )
  const { searchQuery } = useSelector((state: AppState) => state.appContainer)
  // only do once.
  useEffect(() => {
    if (searchQuery && searchQuery !== localSearchQuery) {
      setLocalSearchQuery(searchQuery + '')
    }
  }, [])

  // if (searchQuery && searchQuery !== localSearchQuery) {
  //   setLocalSearchQuery(searchQuery + '')
  // }
  const handleChangeQuery = (event: any) => {
    const value = event.target.value
    setLocalSearchQuery(value)
    debouncedDispacthChangeQuery(value)
  }

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder='Search…'
        value={localSearchQuery}
        onChange={handleChangeQuery}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  )
}

export default GlobalSearch
