import * as React from 'react'
// import { createStyles, makeStyles } from '@material-ui/core'

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//   })
// )

const MessageBox = () => {
  // const theme = useTheme()
  // const classes = useStyles()
  return (
    <React.Fragment>
      {/* <div className={classes.root}>
      <div className={classes.info}> */}
      <h1>401</h1>
      <h3>You are not authorized to use this application.</h3>
      {/* </div>
    </div> */}
    </React.Fragment>
  )
}

export const NotAuthorized = () => <MessageBox />

export default NotAuthorized
