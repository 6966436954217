import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from '../../core/components/Loader'
import { useBrandedApplications } from '../../core/hooks'
import { useRoles } from '../../core/hooks/useRoles'
import { BrandedApplicationEditModel } from '../../core/models'
import { ensureNumber } from '../../core/services/guards'
import BrandedApplicationForm from './brandedApplicationForm'

const BrandedApplication = () => {
  const { id: brandedApplicationId } = useParams<{ id: string }>()
  const {
    getBrandedApplicationEdit,
    saveBrandedApplicationEdit,
    baRequestStatus,
    brandedApplicationEdit,
  } = useBrandedApplications()
  const { isSuperAdmin } = useRoles()
  const canEdit = isSuperAdmin()
  useEffect(() => {
    getBrandedApplicationEdit(ensureNumber(brandedApplicationId))
  }, [getBrandedApplicationEdit, brandedApplicationId])

  const getEmptyEdit = (): BrandedApplicationEditModel => {
    const defaultFormValues: BrandedApplicationEditModel = {
      id: '',
      identifier: '',
      name: '',
      launchUri: '',
      logoUri: '',
      internalName: '',
      brandedApplicationType: '',
      overrideLaunchUri: '',
      overrideLogoUri: '',
      overrideName: '',
      description: '',
      category: '',
    }
    return defaultFormValues
  }

  const wrappedSaveUser = (request: BrandedApplicationEditModel) => {
    saveBrandedApplicationEdit(request)
  }

  const ready =
    (baRequestStatus === 'Failed' || baRequestStatus === 'Success') &&
    brandedApplicationEdit !== undefined
  if (baRequestStatus === 'Unauthorized') {
    // we dont want a spinning if  the request fails just return else endless spinner.
    return null
  }
  return (
    <Loader ready={ready}>
      <BrandedApplicationForm
        brandedApplicationEdit={
          brandedApplicationEdit ? brandedApplicationEdit : getEmptyEdit()
        }
        onSave={wrappedSaveUser}
        canEdit={canEdit}
        callerAction='edit'
      />
    </Loader>
  )
}
export default BrandedApplication
