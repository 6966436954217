import * as React from 'react'
import { Typography } from '@material-ui/core'
import { UserState } from '../../core/models'
import { formatDate } from '../../core/services'

interface EulaProps {
  acceptedEulas?: UserState[]
}

export const Eulas = ({ acceptedEulas }: EulaProps) => {
  if (
    acceptedEulas === undefined ||
    acceptedEulas === null ||
    acceptedEulas.length === 0
  ) {
    return (
      <React.Fragment>
        <Typography variant='caption'>No Eulas Accepted</Typography>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {acceptedEulas.length > 0 &&
        acceptedEulas.map((ac: UserState, index: number) => {
          const addComma = acceptedEulas && index < acceptedEulas.length - 1
          return (
            <React.Fragment key={ac.eulaId}>
              <span style={{ fontWeight: 600 }}>{ac.name}:&nbsp;</span>
              <span>{formatDate(ac.dateSigned)}</span>
              {addComma && <span>{`, `}</span>}
            </React.Fragment>
          )
        })}
      {acceptedEulas.length <= 0 && (
        <span style={{ fontStyle: 'italic' }}>N/A</span>
      )}
    </React.Fragment>
  )
}
