import React, { ReactNode } from 'react'
import clsx from 'clsx'
import {
  makeStyles,
  useTheme,
  fade,
  withStyles,
} from '@material-ui/core/styles'
import { TsaTheme } from '../styles/tsaTheme'
import AppAlert from './alert/AppAlert'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import GlobalSearch from './Search/Search'
import { OidcIdentityContext } from '@rsmus/react-auth'
import { Link } from 'react-router-dom'
import { useRoles } from '../hooks/useRoles'
import { useSelector } from 'react-redux'
import { AppState } from '../redux/store'

const drawerWidth = 240

const useStyles = makeStyles((theme: typeof TsaTheme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}))

export interface AppContainerRoute {
  name: string
  icon: () => JSX.Element
  path: string
}

export interface AppContainerProps {
  routes?: AppContainerRoute[]
  children: ReactNode
}

const ListItemLink = ({ name, icon, path }: AppContainerRoute) => {
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, any>((linkProps, ref) => (
        <Link ref={ref} to={path} {...linkProps} />
      )),
    [path]
  )

  return (
    <ListItem key={name} button component={CustomLink}>
      <ListItemIcon>{icon()}</ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  )
}
///// custom menu for logout
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))
//// Style menu item for logout
const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

const AppContainer = ({ children, routes = [] }: AppContainerProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { logout, user } = React.useContext(OidcIdentityContext)
  const name = user?.profile.given_name + ' ' + user?.profile.family_name
  const { getActiveUser, isSuperAdmin } = useRoles()
  const activeUser = getActiveUser()
  //const showApplicationsMenu = isSuperAdmin()
  const showBulkUploadUsersMenu = isSuperAdmin() // we may want to increate this in the future.
  //const showBrandedApplicationsMenu = isSuperAdmin() // we may want to increate this in the future.
  const { showSearch } = useSelector((state: AppState) => state.appContainer)
  // must be internal and on hte dashboard.tsx scene.
  // to enable look at dashboard useeffect
  const appShowSearch = !activeUser?.isExternal && showSearch
  //console.log('Name is ' + name)
  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleLogout = () => {
    // call logout should redirect to postLogoutRedirectUri
    logout()
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, {
              [classes.hide]: drawerOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant='h6' noWrap>
            RSM Identity Manager
          </Typography>
          {appShowSearch && <GlobalSearch />}
          <div>{name}</div>
          <div>
            <IconButton
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}
              color='inherit'
            >
              <AccountCircle />
            </IconButton>
            <StyledMenu
              id='customized-menu'
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <StyledMenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <ExitToAppIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary='Log Out' />
              </StyledMenuItem>
            </StyledMenu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {routes.map((route) => {
            // // only show Applicaitons if a Super User
            // if (!showApplicationsMenu && route.name === 'Applications') {
            //   return null
            // }

            if (
              !showBulkUploadUsersMenu &&
              route.name === 'Bulk Upload Users'
            ) {
              return null
            }
            // // only show Branded Applications to super users.
            // if (
            //   !showBrandedApplicationsMenu &&
            //   route.name === 'Branded Applications'
            // ) {
            //   return null
            // }

            return <ListItemLink key={route.name} {...route} />
          })}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <AppAlert />

        <div>{children}</div>
      </main>
    </div>
  )
}

export default AppContainer
