import {
  AppBar,
  CircularProgress,
  makeStyles,
  Paper,
  Toolbar,
  Tooltip,
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import {
  GridCellParams,
  GridColDef,
  DataGrid,
  GridApi,
} from '@material-ui/data-grid'
import React, { useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useBrandedApplications } from '../../core/hooks/useBrandedApplications'
import Edit from '@material-ui/icons/Edit'
import SearchIcon from '@material-ui/icons/Search'
import { BrandedApplicationDeleteButton } from './brandedApplicationDeleteButton'
import { BrandedApplicationAddButton } from './brandedApplicationAddButton'
import { DownloadButton } from '../../core/components/Download'
import { TsaTheme } from '../../core/styles/tsaTheme'
import { useRoles } from '../../core/hooks/useRoles'
import { BrandedApplicationEditModel } from '../../core/models'
//import { ensureNumber } from '../../core/services/guards'

const useStyles = makeStyles((theme: typeof TsaTheme) => ({
  root: {
    margin: theme.spacing(0, 0),
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  grow: {
    flexGrow: 1,
  },
  BrandedApplicationsHeading: {
    fontSize: 22,
  },
}))
const BrandedApplications = () => {
  const {
    getBrandedApplications,
    deleteBrandedApplication,
    allBrandedApps,
    setAllBrandedApps,
    baRequestStatus,
  } = useBrandedApplications()
  const { isSuperAdmin } = useRoles()
  const canEdit = isSuperAdmin()
  const classes = useStyles()
  const history = useHistory()
  const removeBrandedAppFromList = (id: string) => {
    // find  the row.
    let removedAnApp: boolean = false
    const deletedOnePosition = allBrandedApps.findIndex((x) => x.id === id)
    if (deletedOnePosition > -1) {
      allBrandedApps.splice(deletedOnePosition, 1)
      removedAnApp = true
    }
    if (removedAnApp) {
      setAllBrandedApps([...allBrandedApps])
    }
  }
  const handleDelete = (brandedApplicationId: string) => {
    deleteBrandedApplication(brandedApplicationId)
      .then((res) => {
        removeBrandedAppFromList(brandedApplicationId)
      })
      .catch((error) => {
        alert(error)
      })
  }

  /// will read up all branded applications
  useEffect(() => {
    getBrandedApplications()
  }, [getBrandedApplications])

  const getColumDefinitions = (): GridColDef[] => {
    const columns: GridColDef[] = [
      {
        field: 'identifier',
        headerName: 'IDENTIFIER',
        width: 250,
        hide: false,
        renderCell: (params: GridCellParams) => {
          if (!apiRef) {
            apiRef = params.api
            console.log('SetApiRef From Action RenderCell')
          }
          return (
            <React.Fragment>
              {canEdit && (
                <BrandedApplicationDeleteButton
                  brandedApplicationId={params.row.id as string}
                  canEdit={canEdit}
                  onDelete={handleDelete}
                  brandedApplicationName={params.row.name}
                />
              )}
              <span>{params.value}</span>
            </React.Fragment>
          )
        },
      },
      {
        field: 'name',
        headerName: 'BRANDED NAME',
        width: 300,
        renderCell: (params: GridCellParams) => {
          return (
            <React.Fragment>
              <NavLink
                to={`/brandedapplication/edit/${params.row.id}`}
                exact={true}
              >
                {canEdit && <Edit fontSize='small' />}
                {!canEdit && <SearchIcon fontSize='small' />}
              </NavLink>
              <NavLink
                to={`/brandedapplication/edit/${params.row.id}`}
                exact={true}
              >
                <span>{params.value}</span>
              </NavLink>
            </React.Fragment>
          )
        },
      },

      {
        field: 'launchUri',
        headerName: 'LAUNCH URI',
        width: 300,
        renderCell: (params: GridCellParams) => {
          const anchorName = params.getValue('name')
          return (
            <React.Fragment>
              <Tooltip title={params.value as string} placement='top-start'>
                <a
                  href={params.value ? (params.value as string) : ''}
                  target='_blank'
                  rel='noreferrer'
                >
                  {anchorName}
                </a>
              </Tooltip>
              {/* <span>{params.value}</span> */}
            </React.Fragment>
          )
        },
      },
      {
        field: 'logoUri',
        headerName: 'LOGO URI',
        width: 150,
        sortable: false,
        renderCell: (params: GridCellParams) => {
          return (
            <React.Fragment>
              <Tooltip title={params.value as string} placement='top-start'>
                <img
                  height={25}
                  alt={params.value as string}
                  src={params.value ? (params.value as string) : ''}
                />
              </Tooltip>
              {/* <span>{params.value}</span> */}
            </React.Fragment>
          )
        },
      },
      {
        field: 'brandedApplicationType',
        headerName: 'TYPE',
        width: 200,
      },
      {
        field: 'description',
        headerName: 'BRANDED DESCRIPTION',
        width: 250,
      },
      {
        field: 'category',
        headerName: 'BRANDED CATEGORY',
        width: 250,
      },
    ]
    return columns
  }

  if (baRequestStatus === 'Failed') {
    return null
  }

  if (baRequestStatus !== 'Success') {
    return (
      <CircularProgress
        style={{
          color: green[500],
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: -12,
          marginLeft: -12,
        }}
      />
    )
  }

  let apiRef: GridApi | undefined
  const HandleAddBrandedApplication = (
    brandedApplication?: BrandedApplicationEditModel
  ) => {
    if (brandedApplication)
      history.push(`/brandedapplication/edit/${brandedApplication.id}`)
    //alert(`BrandedAppIdentifier ${brandedApplication?.identifier}`)
  }

  const handleExportUsers = () => {
    if (apiRef) {
      apiRef.exportDataAsCsv()
    } else {
      console.log('apiRef Not set')
    }
  }

  return (
    <React.Fragment>
      <Paper className={classes.root} variant='outlined'>
        <AppBar position='static'>
          <Toolbar variant='dense'>
            <div className={classes.BrandedApplicationsHeading}>
              Branded Applications ({allBrandedApps.length}){' '}
            </div>
            <div className={classes.grow} />

            {canEdit && (
              <>
                <BrandedApplicationAddButton
                  canEdit={canEdit}
                  onAdd={HandleAddBrandedApplication}
                />
                <DownloadButton
                  toolTip={'Download Branded Applications'}
                  handleDownLoad={handleExportUsers}
                />
              </>
            )}
          </Toolbar>
        </AppBar>
        <div style={{ height: 800, width: '100%' }}>
          <DataGrid
            headerHeight={40}
            rowHeight={40}
            rows={allBrandedApps}
            columns={getColumDefinitions()}
            pageSize={100}
          />
        </div>
      </Paper>
    </React.Fragment>
  )
}

export default BrandedApplications
