import React from 'react'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'

import { Add } from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { TsaTheme } from '../../core/styles/tsaTheme'
import { BrandedApplicationModel } from '../../core/models'
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid'
import { Tooltip } from '@material-ui/core'

const styles = (theme: typeof TsaTheme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    fontWeight: 600,
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'center', // center buttons on the bottom
  },
}))(MuiDialogActions)
export interface ApplicationSelectDialogProps {
  title: string
  isOpen: boolean
  canEdit: boolean
  onCancelHandler: () => void
  onAddHandler: (brandedApp: BrandedApplicationModel) => void
  applications: BrandedApplicationModel[]
}
export const ApplicationSelectDialog = ({
  title,
  isOpen,
  canEdit,
  onCancelHandler,
  applications,
  onAddHandler,
}: ApplicationSelectDialogProps) => {
  const handleClose = () => {
    onCancelHandler()
  }
  const handleOnClick = (event: any, brandedApp: BrandedApplicationModel) => {
    onCancelHandler() // close  then add
    onAddHandler(brandedApp)
  }
  const getColumDefinitions = (): GridColDef[] => {
    const columns: GridColDef[] = [
      {
        field: 'identifier',
        headerName: 'IDENTIFIER',
        width: 250,
        hide: false,
        renderCell: (params: GridCellParams) => {
          return (
            <React.Fragment>
              <Tooltip title='Add Branded Application' placement='top-start'>
                <IconButton
                  disabled={!canEdit}
                  aria-label='Add Branded Application'
                  color='inherit'
                  onClick={(e: any) =>
                    handleOnClick(e, params.row as BrandedApplicationModel)
                  }
                >
                  <Add color='inherit' />{' '}
                  {/*/AddModerator would  be better look into installing @mui/material-icon https://www.npmjs.com/package/@mui/icons-material*/}
                </IconButton>
              </Tooltip>
              <span>{params.value}</span>
            </React.Fragment>
          )
        },
      },
      {
        field: 'name',
        headerName: 'BRANDED NAME',
        width: 300,
      },

      {
        field: 'launchUri',
        headerName: 'LAUNCH URI',
        width: 300,
        renderCell: (params: GridCellParams) => {
          const anchorName = params.getValue('name')
          return (
            <React.Fragment>
              <Tooltip title={params.value as string} placement='top-start'>
                <a
                  href={params.value ? (params.value as string) : ''}
                  target='_blank'
                  rel='noreferrer'
                >
                  {anchorName}
                </a>
              </Tooltip>
              {/* <span>{params.value}</span> */}
            </React.Fragment>
          )
        },
      },
      {
        field: 'logoUri',
        headerName: 'LOGO URI',
        width: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <React.Fragment>
              <Tooltip title={params.value as string} placement='top-start'>
                <img
                  height={25}
                  alt={params.value as string}
                  src={params.value ? (params.value as string) : ''}
                />
              </Tooltip>
              {/* <span>{params.value}</span> */}
            </React.Fragment>
          )
        },
      },
      {
        field: 'brandedApplicationType',
        headerName: 'TYPE',
        width: 200,
      },
      {
        field: 'description',
        headerName: 'BRANDED DESCRIPTION',
        width: 250,
      },
      {
        field: 'category',
        headerName: 'BRANDED CATEGORY',
        width: 250,
        renderCell: (params: GridCellParams) => {
          return (
            <React.Fragment>
              <Tooltip title={params.value as string} placement='top-start'>
                <span>{params.value}</span>
              </Tooltip>
              {/* <span>{params.value}</span> */}
            </React.Fragment>
          )
        },
      },
    ]
    return columns
  }

  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      onClose={handleClose}
      aria-labelledby={title}
      open={isOpen}
    >
      <DialogTitle id={title} onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent color='textPrimary'>
        <div style={{ height: 'auto', width: '100%' }}>
          <DataGrid
            autoHeight={true}
            headerHeight={40}
            rowHeight={40}
            rows={applications}
            columns={getColumDefinitions()}
            pageSize={100}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose} color='default'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
