import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { useFileDownload } from '../../core/hooks/useFileDownload'
import { useFileUpload } from '../../core/hooks/useFileUpload'
import SummaryDialog from '../../core/components/FileUpload/UploadSummaryDialog'
import UploadDialog from '../../core/components/FileUpload/UploadDialog'
import { green } from '@material-ui/core/colors'
import ErrorSharpIcon from '@material-ui/icons/ErrorSharp'
import { makeStyles } from '@material-ui/core'
import { TsaTheme } from '../../core/styles/tsaTheme'
import { useRoles } from '../../core/hooks/useRoles'
const useStyles = makeStyles((theme: typeof TsaTheme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  addIcon: {
    padding: theme.spacing(0, 2),
    // height: '100%',
    // position: 'absolute',
    // pointerEvents: 'none',
    // display: 'flex',
    alignItems: 'right',
    justifyContent: 'right',
  },
  IconError: {
    color: 'red',
    height: '4rem',
    width: '4rem',
    display: 'block',
    margin: '0 auto',
  },
  headerText: {
    textAlign: 'center',
    display: 'block',
    margin: '0 auto',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  mytoolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  button: {
    marginRight: '5px',
  },
  buttonProgress: {
    color: green[500],
    marginLeft: 10,
  },
}))
const acceptedFileTypes = ['.xltx', '.xltm', '.xlsx', '.xls', '.xlsm']
const BulkUploadUsers = () => {
  const { downloadNewTemplate } = useFileDownload()
  const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false)
  const { uploadFileNew, uploadRequestStatus, error, results } = useFileUpload()
  const [summaryDialogOpen, setSummaryDialogOpen] = useState<boolean>(false)
  const { isSuperAdmin } = useRoles()
  const classes = useStyles()
  // Download  Template handler.
  const handleDownload = () => {
    downloadNewTemplate()
  }
  // Upload file Button clickhandler.
  const handleBeginUpload = () => {
    setUploadDialogOpen(true)
  }
  const inProgress = uploadRequestStatus === 'InProgress'
  const canBulkUplLoadUsers = isSuperAdmin()
  //////////////////////////////////////
  const handleSubmitFiles = (droppedFiles: File[]) => {
    // close upload dialog
    setUploadDialogOpen(false)
    uploadFileNew(droppedFiles)
  }
  const handleDismissUpload = () => {
    setUploadDialogOpen(false)
    setSummaryDialogOpen(false)
  }
  const handleReUpload = () => {
    setSummaryDialogOpen(false)

    setUploadDialogOpen(true)
  }
  useEffect(() => {
    if (uploadRequestStatus === 'Success') {
      setUploadDialogOpen(false)
      setSummaryDialogOpen(true)
    } else if (uploadRequestStatus === 'Failed') {
      setUploadDialogOpen(false)
      setSummaryDialogOpen(true)
    }
  }, [uploadRequestStatus]) // eslint-disable-line
  ///////////////////////////
  const getSummaryHeaderError = () => {
    return (
      <div>
        <ErrorSharpIcon className={classes.IconError} />
        <p className={classes.headerText}>{'Users'}</p>
      </div>
    )
  }
  // guar against someone typing in hte url and not having permissions to the  url

  if (!canBulkUplLoadUsers) {
    return null
  }
  return (
    <div>
      <Typography variant='h3'>Bulk Upload Users</Typography>

      <Button
        onClick={handleDownload}
        variant='contained'
        color='primary'
        style={{
          marginRight: 5,
        }}
      >
        DOWNLOAD TEMPLATE
      </Button>

      <Button
        onClick={handleBeginUpload}
        variant='contained'
        color='primary'
        // className={classes.button}
      >
        UPLOAD USERS
      </Button>
      {inProgress && (
        <CircularProgress
          size={24}
          style={{ color: green[500], marginLeft: 10 }}
        />
      )}
      <div>
        <p
          style={{
            fontSize: '1.2rem',
            fontWeight: 700,
            maxWidth: 425,
            marginTop: 20,
            paddingLeft: 5,
            border: '5px solid black',
          }}
        >
          {' '}
          You cannot make bulk edits once users have been added, so please
          review the data's accuracy before uploading.
        </p>
      </div>
      {uploadDialogOpen && (
        <UploadDialog
          open={uploadDialogOpen}
          acceptedFiles={acceptedFileTypes}
          maxFiles={1}
          onUpload={handleSubmitFiles}
          onDissmiss={handleDismissUpload}
        />
      )}

      {uploadRequestStatus === 'Failed' && (
        <SummaryDialog
          open={summaryDialogOpen}
          summaryHeader={getSummaryHeaderError()}
          onDissmiss={handleDismissUpload}
          onReUpload={handleReUpload}
          results={results}
          error={error}
        />
      )}
      {uploadRequestStatus === 'Success' && (
        <SummaryDialog
          context={'Users'}
          open={summaryDialogOpen}
          onDissmiss={handleDismissUpload}
          onReUpload={handleReUpload}
          results={results}
        />
      )}
    </div>
  )
}

export default BulkUploadUsers
