import { User } from 'oidc-client'
import { ActionType, createAction, createAsyncAction } from 'typesafe-actions'
import { globalSearchAction } from '.'
import { HttpError } from '../../services/httpError'

export type AlertMessage = Error | HttpError | string

export const setUser = createAction('SET_USER')<User | undefined>()
export const PushAlert = createAction('PUSH_ALERT')<AlertMessage | undefined>()
export const PopAlert = createAction('POP_ALERT')<undefined>()
export const setShowSearch = createAction('SET_SHOW_SEARCH')<
  boolean | undefined
>()

export const apiRequestAsync = createAsyncAction(
  'REQUEST_BEGIN',
  'REQUEST_SUCCESS',
  'REQUEST_FAIL'
)<undefined, undefined, Error>()

export type Actions =
  | ActionType<typeof setUser>
  | ActionType<typeof PushAlert>
  | ActionType<typeof PopAlert>
  | ActionType<typeof apiRequestAsync.request>
  | ActionType<typeof apiRequestAsync.success>
  | ActionType<typeof apiRequestAsync.failure>
  | ActionType<typeof globalSearchAction>
  | ActionType<typeof setShowSearch>
