import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunk, { ThunkAction, ThunkMiddleware } from 'redux-thunk'
import { Actions } from './Actions/actions'
import {
  appContainerReducer as appContainer,
  AppContainerState,
  apiRequestReducer as request,
  ApiRequestState,
} from './reducers'

export interface AppState {
  appContainer: AppContainerState
  request: ApiRequestState
}

export type IPThunkAction<R = void> = ThunkAction<
  R,
  AppState,
  undefined,
  Actions
>

const rootReducer = combineReducers<AppState, Actions>({
  appContainer,
  request,
})

const compose = composeWithDevTools({})

export const store = createStore(
  rootReducer,
  {},
  compose(applyMiddleware(thunk as ThunkMiddleware<AppState, Actions>))
)
