import {
  AppBar,
  Checkbox,
  CircularProgress,
  makeStyles,
  Paper,
  Toolbar,
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import {
  GridCellParams,
  GridColDef,
  DataGrid,
  GridApi,
} from '@material-ui/data-grid'
import React, { useEffect } from 'react'
import { useApplications } from '../../core/hooks/useApplications'
import { DownloadButton } from '../../core/components/Download'
import { TsaTheme } from '../../core/styles/tsaTheme'
import { useRoles } from '../../core/hooks/useRoles'

const useStyles = makeStyles((theme: typeof TsaTheme) => ({
  root: {
    margin: theme.spacing(0, 0),
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  grow: {
    flexGrow: 1,
  },
  BrandedApplicationsHeading: {
    fontSize: 22,
  },
}))
const Applications = () => {
  const { getApplications, setAllApps, allApps, appRequestStatus } =
    useApplications()
  const { isSuperAdmin } = useRoles()
  const canEdit = isSuperAdmin()
  const classes = useStyles()

  /// will read up all  applications
  useEffect(() => {
    getApplications()
  }, [getApplications])
  useEffect(() => {
    const baseallApps = allApps.sort((a, b) => {
      // sort by Description

      const nameCompare = String(a.description).localeCompare(b.description)
      return nameCompare
    })
    setAllApps(baseallApps)
  }, [allApps, setAllApps])

  const getColumDefinitions = (): GridColDef[] => {
    const columns: GridColDef[] = [
      {
        field: 'identifier',
        headerName: 'IDENTIFIER',
        width: 250,
        hide: false,
        renderCell: (params: GridCellParams) => {
          if (!apiRef) {
            apiRef = params.api
            console.log('SetApiRef From Action RenderCell')
          }
          return (
            <React.Fragment>
              <span>{params.value}</span>
            </React.Fragment>
          )
        },
      },
      {
        field: 'name',
        headerName: 'NAME',
        width: 300,
        renderCell: (params: GridCellParams) => {
          return (
            <React.Fragment>
              {/* <NavLink
                to={`/brandedapplication/edit/${params.row.id}`}
                exact={true}
              >
                <Edit fontSize='small' />
              </NavLink>
              <NavLink
                to={`/brandedapplication/edit/${params.row.id}`}
                exact={true}
              >
               
              </NavLink> */}
              <span>{params.value}</span>
            </React.Fragment>
          )
        },
      },

      {
        field: 'description',
        headerName: 'DESCRIPTION',
        width: 300,
      },
      {
        field: 'externalManaged',
        headerName: 'EXTERNAL MANAGED',
        width: 250,
        renderCell: (params: GridCellParams) => {
          const checked = params.value as boolean

          return (
            <Checkbox
              disabled={true}
              defaultChecked={checked}
              color='primary'
            />
          )
        },
      },
      {
        field: 'allowMultipleClaims',
        headerName: 'ALLOW MULTIPLE ROLES',
        width: 250,
        renderCell: (params: GridCellParams) => {
          const checked = params.value as boolean

          return (
            <Checkbox
              disabled={true}
              defaultChecked={checked}
              color='primary'
            />
          )
        },
      },
      {
        field: 'applicationType',
        headerName: 'TYPE',
        width: 200,
      },
    ]
    return columns
  }

  if (appRequestStatus === 'Failed') {
    return null
  }

  if (appRequestStatus !== 'Success') {
    return (
      <CircularProgress
        style={{
          color: green[500],
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: -12,
          marginLeft: -12,
        }}
      />
    )
  }

  let apiRef: GridApi | undefined

  const handleExportUsers = () => {
    if (apiRef) {
      apiRef.exportDataAsCsv()
    } else {
      console.log('apiRef Not set')
    }
  }

  return (
    <React.Fragment>
      <Paper className={classes.root} variant='outlined'>
        <AppBar position='static'>
          <Toolbar variant='dense'>
            <div className={classes.BrandedApplicationsHeading}>
              Applications ({allApps.length}){' '}
            </div>
            <div className={classes.grow} />

            {canEdit && (
              <>
                <DownloadButton
                  toolTip={'Download Applications'}
                  handleDownLoad={handleExportUsers}
                />
              </>
            )}
          </Toolbar>
        </AppBar>
        <div style={{ height: 800, width: '100%' }}>
          <DataGrid
            headerHeight={40}
            rowHeight={40}
            rows={allApps}
            columns={getColumDefinitions()}
            pageSize={100}
          />
        </div>
      </Paper>
    </React.Fragment>
  )
}

export default Applications
