import { env } from '@rsmus/react-env-image'
import { Upload, timeout } from '../services/http'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiRequestAsync } from '../redux/Actions/actions'
import { HttpRequestState } from '../models/core'
import { useHttpErrors } from './useHttpError'
import { FileUploadResults } from '../models'
// let controller = new AbortController()

export const useFileUpload = () => {
  const dispatch = useDispatch()
  // const { signal } = controller
  const baseUrl = env('REACT_APP_ODATA_BASE_URL')
  const [uploadRequestStatus, setUploadRequestStatus] =
    useState<HttpRequestState>('Idle')
  const { handleNotOkResponse, handleRequestError } = useHttpErrors()
  const [results, setResults] = useState<FileUploadResults>()
  const [error, setError] = useState<Error>()

  const uploadFile = useCallback(
    async (organizationId: string, files: File[]) => {
      if (!baseUrl) {
        return
      }

      try {
        setUploadRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        // set time out to be 10 minutes.
        const response = await timeout(
          600000,
          Upload(`${baseUrl}/api/files/${organizationId}/upload`, files)
        )
        if (response.ok) {
          const result = await response.json()
          setResults(result)
          setUploadRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setUploadRequestStatus('Failed')
          const myhttperr = await handleNotOkResponse(response)
          setError(myhttperr)
        }
      } catch (error) {
        setUploadRequestStatus('Failed')
        handleRequestError(error as Error)
        setError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )
  const uploadHrFeedFile = useCallback(
    async (organizationId: string, files: File[]) => {
      if (!baseUrl) {
        return
      }

      try {
        setUploadRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        // set time out to be 10 minutes.
        const response = await timeout(
          600000,
          Upload(`${baseUrl}/api/files/uploadhrfeed`, files)
        )
        if (response.ok) {
          const result = await response.json()
          setResults(result)
          setUploadRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setUploadRequestStatus('Failed')
          const myhttpErr = await handleNotOkResponse(response)

          setError(myhttpErr as Error)
        }
      } catch (error) {
        setUploadRequestStatus('Failed')
        handleRequestError(error as Error)
        setError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )
  const uploadFileNew = useCallback(
    async (files: File[]) => {
      if (!baseUrl) {
        return
      }

      try {
        setUploadRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        // set time out to be 10 minutes.
        const response = await timeout(
          600000,
          Upload(`${baseUrl}/api/files/upload`, files)
        )
        if (response.ok) {
          const result = await response.json()
          setResults(result)
          setUploadRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setUploadRequestStatus('Failed')
          const myhttpErr = await handleNotOkResponse(response)

          setError(myhttpErr as Error)
        }
      } catch (error) {
        setUploadRequestStatus('Failed')
        handleRequestError(error as Error)
        setError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )

  return {
    uploadFile,
    uploadHrFeedFile,
    uploadFileNew,
    uploadRequestStatus,
    results,
    error,
  }
}
