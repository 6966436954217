import React, { useState } from 'react'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import {
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { FileUploadResults } from '../../models'
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    SummaryIcon: {
      color: theme.palette.grey[500],
      height: '4rem',
      width: '4rem',
      display: 'block',
      margin: '0 auto',
    },
    dropZone: {
      color: 'white',
      padding: '1rem',
      textAlign: 'center',
      borderRadius: '4rem',
    },
    error: {
      listStyleType: 'none',
      margin: 0,
      padding: 0,
    },
    footer: {
      display: 'block',
      margin: '0 auto',
    },
    table: {
      minWidth: '33rem',
      //maxWidth: '33rem',
    },
    list: {
      color: 'black',
      minWidth: '35rem',
      maxWidth: '35rem',
    },
    IconSuccess: {
      color: 'green',
      height: '4rem',
      width: '4rem',
      display: 'block',
      margin: '0 auto',
    },
    IconFailed: {
      color: 'orange',
      height: '4rem',
      width: '4rem',
      display: 'block',
      margin: '0 auto',
    },
    header: {
      height: '6rem',
      display: 'block',
      margin: '0 auto',
    },
    headerText: {
      textAlign: 'center',
      display: 'block',
      margin: '0 auto',
    },
  })

interface SummaryDialogProps extends WithStyles<typeof styles> {
  open: boolean
  SummaryButtonText?: string
  onDissmiss: () => void
  onReUpload: () => void
  maxFiles?: number
  summaryHeader?: React.ReactNode
  summaryContent?: React.ReactNode
  results?: FileUploadResults
  error?: Error
  context?: string
}

export interface SummaryDialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onDismiss: () => void
}

const DialogTitle = withStyles(styles)((props: SummaryDialogTitleProps) => {
  const { children, classes, onDismiss: onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions)
const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.common.white,
    },
  })
)(TableCell)

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow)

const SummaryDialog = withStyles(styles)(
  ({
    open,
    classes,
    onDissmiss,
    onReUpload,
    maxFiles = 10,
    summaryHeader,
    summaryContent,
    SummaryButtonText = 'Upload Another Document',
    results,
    error,
    context,
  }: SummaryDialogProps) => {
    // const countItems = (item: string) => {
    //   let count = 0
    //   if (!results) {
    //     return count
    //   }
    //   results.forEach(row => {
    //     if (row.operation === item) {
    //       count++
    //     }
    //   })
    //   return count
    // }

    const handleClose = () => {
      onDissmiss()
    }
    const getErrors = (): JSX.Element[] => {
      // guard condition
      if (!(results && results.errors)) {
        return []
      }
      const errors = results.errors

      return Object.keys(errors).map((k) => {
        const value = errors ? errors[k] : ''

        return (
          <StyledTableRow key={k}>
            <StyledTableCell component='th' scope='row'>
              {k}
            </StyledTableCell>
            <StyledTableCell align='left'>
              <div dangerouslySetInnerHTML={{ __html: value + '' }}></div>
            </StyledTableCell>
          </StyledTableRow>
        )
      })
    }

    const getDefaultContent = () => {
      return (
        <React.Fragment>
          {!error ? (
            <>
              <List component='div' role='list' className={classes.list}>
                <ListItem>
                  <ListItemText
                    primary={
                      results?.succeded === 1
                        ? results?.succeded + ' user successfully added.'
                        : results?.succeded + ' users successfully added.'
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={results?.failed + ' failed to upload.'}
                  />
                </ListItem>
              </List>
              {results && results?.failed !== 0 && (
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align='left'>
                          Email/Identifier
                        </StyledTableCell>
                        <StyledTableCell align='left'>Error</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{results.errors && getErrors()}</TableBody>
                  </Table>
                </TableContainer>
              )}
            </>
          ) : (
            <p className={classes.list}>{'Error: ' + error.message}</p>
          )}
        </React.Fragment>
      )
    }

    const [maxNumberFiles, setMaxNumberFiles] = useState(100)

    if (maxFiles && maxFiles !== maxNumberFiles) {
      setMaxNumberFiles(maxFiles)
    }

    const fileSummaryContent = summaryContent
      ? summaryContent
      : getDefaultContent()

    const fileSummaryHeader = summaryHeader ? (
      summaryHeader
    ) : results?.failed === 0 ? (
      <div>
        <CheckCircleIcon className={classes.IconSuccess} />
        <p className={classes.headerText}>{context}</p>
      </div>
    ) : (
      <div>
        <ErrorIcon className={classes.IconFailed} />
        <p className={classes.headerText}>{context}</p>
      </div>
    )

    return (
      <Dialog
        maxWidth={false}
        fullWidth={false}
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onDismiss={onDissmiss}>
          <Toolbar className={classes.header}>{fileSummaryHeader}</Toolbar>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.dropZone}>{fileSummaryContent}</div>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Toolbar>
            <Button variant='contained' color='primary' onClick={onReUpload}>
              {SummaryButtonText}
            </Button>
          </Toolbar>
        </DialogActions>
      </Dialog>
    )
  }
)
export default SummaryDialog
