import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import {
  Button,
  CardActions,
  FormGroup,
  Grid,
  LinearProgress,
  Tooltip,
} from '@material-ui/core'
import { TsaTheme } from '../../core/styles/tsaTheme'

import { green } from '@material-ui/core/colors'

import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  ApplicationClaimsRequest,
  ApplicationUserAttributeViewModelRequest,
  BrandedApplicationEditModel,
} from '../../core/models'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: typeof TsaTheme) => ({
  root: {
    padding: '2rem',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      minWidth: 275,
      width: '100%',
    },
    minWidth: 275,
  },
  formControl: {
    margin: theme.spacing(1),
    marginBottom: '2rem',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  errorText: {
    color: theme.palette.error.main,
  },
  title: {
    padding: theme.spacing(3, 1),
  },
  pos: {
    marginBottom: 12,
  },
  eula: {
    marginLeft: 12,
  },
  eulaLabel: {
    fontWeight: 600,
    marginRight: '.5rem',
  },
}))

export interface BrandedApplicationFormProps {
  brandedApplicationEdit: BrandedApplicationEditModel
  onSave: (request: BrandedApplicationEditModel) => void
  canEdit: boolean
  callerAction: string
}

// the uri validation regular expression came from stackoverflow.com
//https://stackoverflow.com/questions/161738/what-is-the-best-regular-expression-to-check-if-a-string-is-a-valid-url
// tward  the bottom of article  JavaScript Version
const formValidationSchema = Yup.object().shape({
  overrideName: Yup.string()
    .nullable()
    .max(256, 'Too Long, Max 256 Characters.'),
  overrideLaunchUri: Yup.string()
    .nullable()
    .matches(
      /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
      'Please enter valid URI'
    )
    .max(512, 'Too Long, Max 512 Characters.'),
  overrideLogoUri: Yup.string()
    .nullable()
    .matches(
      /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
      'Please enter valid URI'
    )
    .max(512, 'Too Long, Max 512 Characters.'),
  brandedApplicationType: Yup.string()
    .required('Required')
    .max(75, 'Too Long, Max 75 Characters.'),
  internalName: Yup.string()
    .required('Required')
    .max(256, 'Too Long, Max 256 Characters.'),
  descripiton: Yup.string()
    .nullable()
    .max(256, 'Too Long, Max 256 Characters.'),
  category: Yup.string().nullable().max(256, 'Too Long, Max 256 Characters.'),
  identifier: Yup.string()
    .required('Required')
    .min(1, 'Too short, Min 1 Character.')
    .max(256, 'Too Long, Max 256 Characters.'),
})

var HeaderComponent = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.title}>
      <Grid item xs={12}>
        <Typography variant='h4' color='textPrimary' gutterBottom>
          {children}
        </Typography>
      </Grid>
    </Grid>
  )
}

const BrandedApplicationForm = ({
  brandedApplicationEdit,
  onSave,
  canEdit,
  callerAction,
}: BrandedApplicationFormProps) => {
  const classes = useStyles()
  const history = useHistory()
  const GoBack = () => {
    history.goBack()
  }

  const disableEdit = !canEdit
  return (
    <Formik
      initialValues={brandedApplicationEdit}
      validationSchema={formValidationSchema}
      // this is the   function for the save. Which  converts form value to the
      //UpdateUserRequest model
      onSubmit={(values, { setSubmitting }) => {
        if (brandedApplicationEdit) {
          brandedApplicationEdit.internalName = values.internalName
          brandedApplicationEdit.overrideLaunchUri = values.overrideLaunchUri
          brandedApplicationEdit.overrideLogoUri = values.overrideLogoUri
          brandedApplicationEdit.brandedApplicationType =
            values.brandedApplicationType
          brandedApplicationEdit.overrideName = values.overrideName
          brandedApplicationEdit.description = values.description
          brandedApplicationEdit.category = values.category
          brandedApplicationEdit.identifier = values.identifier
          onSave(brandedApplicationEdit)
        } else {
          return
        }
      }}
    >
      {({
        submitForm,
        isSubmitting,
        errors,
        touched,
        values,
        dirty,
        setFieldValue,
        setFieldTouched,
      }) => {
        return (
          <Card className={classes.root} variant='outlined'>
            <CardContent>
              <Form>
                <FormGroup>
                  <HeaderComponent>
                    {`${
                      brandedApplicationEdit?.overrideName
                        ? brandedApplicationEdit.name
                        : brandedApplicationEdit?.name
                    } Branded Application`}
                  </HeaderComponent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Tooltip
                        title='This is an extended application description or tag line.'
                        placement='top-start'
                      >
                        <Field
                          component={TextField}
                          disabled={disableEdit}
                          variant='outlined'
                          name='description'
                          label='Branded Description'
                          autoComplete='off'
                          helperText={
                            touched.description ? errors.description : ''
                          }
                          error={
                            touched.description && Boolean(errors.description)
                          }
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={TextField}
                        disabled={disableEdit}
                        variant='outlined'
                        name='category'
                        label='Branded Category'
                        autoComplete='off'
                        helperText={touched.category ? errors.category : ''}
                        error={touched.category && Boolean(errors.category)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Tooltip
                        title='Identity Provider Branded Name'
                        placement='top-start'
                      >
                        <Field
                          component={TextField}
                          disabled={true}
                          variant='outlined'
                          name='name'
                          label='Branded Name'
                          autoComplete='off'
                          helperText={touched.name ? errors.name : ''}
                          error={touched.name && Boolean(errors.name)}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={TextField}
                        disabled={disableEdit}
                        variant='outlined'
                        name='overrideName'
                        label={`Override Branded Name (${brandedApplicationEdit?.name})`}
                        autoComplete='off'
                        helperText={
                          touched.overrideName ? errors.overrideName : ''
                        }
                        error={
                          touched.overrideName && Boolean(errors.overrideName)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Tooltip
                        title='Identity Provider Launch Uri'
                        placement='top-start'
                      >
                        <Field
                          component={TextField}
                          disabled={true}
                          variant='outlined'
                          name='launchUri'
                          label='Launch URI'
                          autoComplete='off'
                          helperText={touched.launchUri ? errors.launchUri : ''}
                          error={touched.launchUri && Boolean(errors.launchUri)}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={TextField}
                        disabled={disableEdit}
                        variant='outlined'
                        name='overrideLaunchUri'
                        label='Override LaunchUri'
                        autoComplete='off'
                        helperText={
                          touched.overrideLaunchUri
                            ? errors.overrideLaunchUri
                            : ''
                        }
                        error={
                          touched.overrideLaunchUri &&
                          Boolean(errors.overrideLaunchUri)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Tooltip
                        title='Identity Provider Logo Uri'
                        placement='top-start'
                      >
                        <Field
                          component={TextField}
                          disabled={true}
                          variant='outlined'
                          name='logoUri'
                          label='Logo URI'
                          autoComplete='off'
                          helperText={touched.logoUri ? errors.logoUri : ''}
                          error={touched.logoUri && Boolean(errors.logoUri)}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={TextField}
                        disabled={disableEdit}
                        variant='outlined'
                        name='overrideLogoUri'
                        label='Override LogoUri'
                        autoComplete='off'
                        helperText={
                          touched.overrideLogoUri ? errors.overrideLogoUri : ''
                        }
                        error={
                          touched.overrideLogoUri &&
                          Boolean(errors.overrideLogoUri)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={TextField}
                        disabled={true}
                        variant='outlined'
                        name='internalName'
                        label='Internal App Name'
                        autoComplete='off'
                        helperText={
                          touched.internalName ? errors.internalName : ''
                        }
                        error={
                          touched.internalName && Boolean(errors.internalName)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={TextField}
                        disabled={true}
                        variant='outlined'
                        name='brandedApplicationType'
                        label='Application Type'
                        autoComplete='off'
                        helperText={
                          touched.brandedApplicationType
                            ? errors.brandedApplicationType
                            : ''
                        }
                        error={
                          touched.brandedApplicationType &&
                          Boolean(errors.brandedApplicationType)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Tooltip
                        title='Identity/Internal Provider Identifier'
                        placement='top-start'
                      >
                        <Field
                          component={TextField}
                          disabled={true}
                          variant='outlined'
                          name='identifier'
                          label='Branded Application Identifier'
                          autoComplete='off'
                          helperText={
                            touched.identifier ? errors.identifier : ''
                          }
                          error={
                            touched.identifier && Boolean(errors.identifier)
                          }
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  {isSubmitting && <LinearProgress />}
                </FormGroup>
              </Form>
            </CardContent>
            <CardActions>
              <Button
                variant='contained'
                color='primary'
                size='large'
                onClick={GoBack}
              >
                Back
              </Button>
              {canEdit && (
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  disabled={isSubmitting || !dirty}
                  onClick={submitForm}
                >
                  Save
                </Button>
              )}
            </CardActions>
          </Card>
        )
      }}
    </Formik>
  )
}

export default BrandedApplicationForm
