import React from 'react'
import Button from '@material-ui/core/Button'
// import Typography from '@material-ui/core/Typography'
// import { CardHeader, Grid } from '@material-ui/core'
// import { TsaTheme } from '../../core/styles/tsaTheme'
import CircleIcon from '@material-ui/icons/FiberManualRecord'
export interface OrgActiveButtonProps {
  isLost?: boolean
}
export const OrgActiveButton = ({ isLost }: OrgActiveButtonProps) => {
  return (
    <Button
      variant='text'
      startIcon={<CircleIcon htmlColor={isLost ? 'red' : 'green'} />}
    >
      {isLost ? 'Lost' : 'Active'}
    </Button>
  )
}
