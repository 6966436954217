import {
  AppBar,
  Button,
  Checkbox,
  makeStyles,
  Paper,
  Toolbar,
} from '@material-ui/core'
import {
  GridCellParams,
  GridColDef,
  DataGrid,
  GridCellValue,
  GridValueFormatterParams,
  GridFilterModelParams,
  GridFilterModelState,
  GridApi,
  //GridApi,
} from '@material-ui/data-grid'
import React, { useEffect, useState } from 'react'
// import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
// import { AppState } from '../../core/redux/store'

// import { useOrganizationUsers } from '../../core/hooks'
import { Claims, Eulas } from '../users/index'
import {
  ApplicationClaim,
  HttpRequestState,
  ParentOu,
  UserResults,
  UserState,
} from '../../core/models'
import { Loader } from '../../core/components/Loader'
import { TsaTheme } from '../../core/styles/tsaTheme'
import { Edit } from '@material-ui/icons'
import { SearchUsersButton, UserAction } from '../users'
import { BulkUsersButton } from '../users/bulkUsersButton'
import { formatDate } from '../../core/services'
import { useIdpUser } from '../../core/hooks/useIdpUser'
import { DownloadButton } from '../../core/components/Download'

const useStyles = makeStyles((theme: typeof TsaTheme) => ({
  root: {
    margin: theme.spacing(0, 0),
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  grow: {
    flexGrow: 1,
  },
  ManageUserHeading: {
    fontSize: 22,
  },
}))

export interface OrganizationUsersProps {
  organizationId: string
  onActiveChange?: (id: string, active: boolean) => void
  onRemoveChange?: (id: string, remove: boolean) => void
  forceUserRefresh?: () => void
  canEdit: boolean
  canAddEditRsmUser: boolean
  results: UserResults[]
  requestStatus: HttpRequestState
  setExternalDirty?: (value: boolean) => void
  replaceUser?: (userResult: UserResults) => void
  filterUserId?: string
}

const OrganizationUsers = ({
  organizationId,
  onActiveChange,
  onRemoveChange,
  forceUserRefresh,
  canEdit,
  canAddEditRsmUser,
  results,
  requestStatus,
  setExternalDirty,
  replaceUser,
  filterUserId,
}: OrganizationUsersProps) => {
  // const {
  //   searchOrganizationUsers,
  //   results,
  //   setResults,
  //   requestStatus,
  // } = useOrganizationUsers()
  //const [confirmDlgOpen, setConfirmDlgOpen] React.useState(false)
  const { reactivateUser, unlockUser, resetPasswordForUser, userResults } =
    useIdpUser()
  const classes = useStyles()
  const [filterCaption, setFilterCaption] = useState<string>('User Filter Off')
  // const { searchQuery } = useSelector((state: AppState) => state.appContainer)
  // this will handle the userResult Change when  the ResetPassword or UnLock Is clicked.
  // the UseIDP.userResults will change and thenl  trigger the effect.
  useEffect(() => {
    if (userResults) {
      const position = results.findIndex((x) => x.id === userResults?.rsmUid)
      if (position > -1) {
        results[position].status = userResults.status
        results[position].actionStatus = userResults.status
        if (replaceUser) replaceUser(results[position])
      }
    }
    // we only want if the userResults changes.
    // eslint-disable-next-line
  }, [userResults])
  const handleResetPassword = async (userId: string) => {
    //alert('handleResetPassword :' + userid)
    resetPasswordForUser(userId)
    // resetPasswordForUser(userId).then(() => {
    //   // find the user
    //   const position = results.findIndex((x) => x.id === userId)
    //   if (position > -1) {
    //     results[position].status = userResults ? userResults.status : 'RECOVERY'
    //     results[position].actionStatus = userResults
    //       ? userResults.status
    //       : 'RECOVERY'
    //     if (replaceUser) replaceUser(results[position])
    //   }
    // })
  }
  const handleResendWelcome = async (userId: string) => {
    reactivateUser(userId)
  }
  const handleUnlock = async (userId: string) => {
    unlockUser(userId)
    // unlockUser(userId).then(() => {
    //   // find the user
    //   const position = results.findIndex((x) => x.id === userId)
    //   if (position > -1) {
    //     results[position].status = userResults ? userResults.status : 'ACTIVE'
    //     results[position].actionStatus = userResults
    //       ? userResults.status
    //       : 'ACTIVE'
    //     if (replaceUser) replaceUser(results[position])
    //   }
    // })
  }

  const handleActiveChange = (event: any, checked: boolean, userid: string) => {
    if (onActiveChange) onActiveChange(userid, checked)
  }
  const handleRemoveChange = (event: any, checked: boolean, userid: string) => {
    // will update a array of userd to remove.
    if (onRemoveChange) onRemoveChange(userid, checked)
    // mark form dirty on change
    if (setExternalDirty) setExternalDirty(true)
  }

  const sortComparatorCheckbox = (
    v1: GridCellValue,
    v2: GridCellValue,
    param1: GridCellParams,
    param2: GridCellParams
  ) => {
    const checked1 = param1.value as boolean
    const checked2 = param2.value as boolean
    const checked1Value = checked1 ? 1 : 0
    const checked2Value = checked2 ? 1 : 0
    return checked1Value - checked2Value
  }
  // do not need any more roles value is a string that can be sorted.
  // const sortComparatorRoles = (
  //   v1: GridCellValue,
  //   v2: GridCellValue,
  //   param1: GridCellParams,
  //   param2: GridCellParams
  // ) => {
  //   const claims1 = param1.value as ApplicationClaim[]
  //   const claims2 = param2.value as ApplicationClaim[]
  //   return claims1.length - claims2.length
  // }
  const getColumDefinitions = () => {
    const columns: GridColDef[] = [
      { field: 'id', hide: true },
      { field: 'claims', hide: true },
      { field: 'type', hide: true },
      { field: 'acceptedEulas', hide: true },
      {
        field: 'name',
        headerName: 'NAME',
        width: 320,
        renderCell: (params: GridCellParams) => {
          if (!apiRef) {
            apiRef = params.api
            console.log('SetApiRef From Action RenderCell')
          }
          return (
            <React.Fragment>
              <NavLink
                to={`/organizations/${organizationId}/users/${params.getValue(
                  'id'
                )}`}
                exact={true}
              >
                <Edit fontSize='small' />
              </NavLink>
              <NavLink
                to={`/organizations/${organizationId}/users/${params.getValue(
                  'id'
                )}`}
                exact={true}
              >
                <span>{params.value}</span>
              </NavLink>
            </React.Fragment>
          )
        },
      },
      {
        field: 'appClaims',
        headerName: 'ROLE',
        width: 320,
        // resizable: true, // need to pay for this feature.
        renderCell: (params: GridCellParams) => {
          const claims = params.getValue('claims') as ApplicationClaim[]
          return <Claims claims={claims} />
        },
      },
      { field: 'email', headerName: 'EMAIL', width: 338 },
      {
        field: 'active',
        headerName: 'ACTIVE',
        width: 130,
        renderCell: (params: GridCellParams) => {
          const checked = params.value as boolean
          const userid = params.getValue('id') as string
          return (
            <Checkbox
              disabled={!canEdit}
              defaultChecked={checked}
              color='primary'
              onChange={(event, checked) =>
                handleActiveChange(event, checked, userid)
              }
            />
          )
        },
        sortComparator: sortComparatorCheckbox,
      },
      {
        field: 'flatAcceptedEulas',
        headerName: 'EULAS',
        width: 200,
        renderCell: (params: GridCellParams) => {
          const eulasSigned = params.getValue('acceptedEulas') as UserState[]
          return <Eulas acceptedEulas={eulasSigned} />
        },
      },
      {
        field: 'remove',
        headerName: 'REMOVE',
        width: 140,

        renderCell: (params: GridCellParams) => {
          const checked = params.value as boolean
          const userid = params.getValue('id') as string
          return (
            <Checkbox
              checked={checked}
              disabled={!canEdit}
              color='primary'
              onChange={(event, checked) =>
                handleRemoveChange(event, checked, userid)
              }
            />
          )
        },
        sortable: false, // could not get sort to work with no underlying value in row data.
      },
      {
        field: 'lastLogin',
        headerName: 'LAST LOGIN',
        //type: 'date',
        width: 150,
        valueFormatter: (params: GridValueFormatterParams) =>
          formatDate(params.value as Date),
      },
      {
        field: 'status',
        headerName: 'STATUS',
        width: 150,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value === 'RECOVERY'
            ? 'PW Reset Sent'
            : (params.value as string)
        },
      },
      {
        field: 'actionStatus',
        headerName: 'ACTION',
        //type: 'date',
        width: 260,
        renderCell: (params: GridCellParams) => {
          const userStatus = params.value as string
          const userId = params.getValue('id') as string
          const userType = params.getValue('type') as ParentOu
          const isUserFederated = params.getValue('rsmIsFederated') as boolean
          return (
            <UserAction
              userId={userId}
              type={userType}
              status={userStatus}
              isFederated={isUserFederated}
              onResendWelcome={(userId: string) => handleResendWelcome(userId)}
              onResetPassword={(userId: string) => handleResetPassword(userId)}
              onUnlock={(userId: string) => handleUnlock(userId)}
              canEdit={canEdit}
            />
          )
        },
      },
    ]
    return columns
  }
  var apiRef: GridApi | undefined
  console.log('Initial SetApiRef undefined')

  const handleFilterModelCHange = (params: GridFilterModelParams) => {
    // we set the ref here in the chance the  there is no rows to render.
    if (!apiRef) {
      apiRef = params.api
      console.log('SetApiRef From Action handleFilterModelChange')
    }
    // const vrows = params.visibleRows
    // const allrows = params.rows
    // const apiBase = params.api
    // vrows.forEach((arow, index, fallrows) => {
    //   if (arow) {
    //     console.log(arow)
    //     //apiBase.SetCellValue()
    //   }
    // })
  }
  // we will set the intial filter if there is one.
  let intitalfilter: undefined | GridFilterModelState = undefined
  if (filterUserId) {
    const filterValue = filterCaption === 'User Filter Off' ? filterUserId : ''
    intitalfilter = {
      items: [
        {
          columnField: 'id',
          operatorValue: 'contains',
          value: filterValue,
        },
      ],
    }
  }
  const toggleUserFilter = () => {
    if (apiRef) {
      if (intitalfilter) {
        intitalfilter.items[0].value =
          intitalfilter.items[0].value === ''
            ? (intitalfilter.items[0].value = filterUserId)
            : ''
        apiRef.setFilterModel(intitalfilter)
        if (intitalfilter.items[0].value === '') {
          setFilterCaption('User Filter On')
        } else {
          setFilterCaption('User Filter Off')
        }
      }
    }
  }
  const handleExportUsers = () => {
    if (apiRef) {
      apiRef.exportDataAsCsv()
    } else {
      console.log('apiRef Not set')
    }
  }

  const ready = requestStatus === 'Success'
  if (requestStatus === 'Failed' || requestStatus === 'Unauthorized') {
    // we dont want a spinning if  the reques fails just return else endless spinner.
    return null
  }
  return (
    <Paper className={classes.root} variant='outlined'>
      <AppBar position='static'>
        <Toolbar variant='dense'>
          <div className={classes.ManageUserHeading}>
            Manage Users({results.length}){' '}
            {intitalfilter && (
              <Button
                style={{ marginLeft: 10 }}
                aria-label='Toggle User filter'
                variant='contained'
                color='primary'
                onClick={toggleUserFilter}
              >
                {filterCaption}
              </Button>
            )}
          </div>
          <div className={classes.grow} />
          {canEdit && (
            <>
              <SearchUsersButton
                organizationId={organizationId}
                canAddEditRsmUser={canAddEditRsmUser}
              />
              <BulkUsersButton
                organizationId={organizationId}
                canAddEditRsmUser={canAddEditRsmUser}
                forceUserRefresh={forceUserRefresh}
              />
              <DownloadButton
                toolTip={'Download Users'}
                handleDownLoad={handleExportUsers}
              />
            </>
          )}
        </Toolbar>
      </AppBar>
      <Loader ready={ready}>
        <div style={{ height: '500px', width: '100%' }}>
          <DataGrid
            autoHeight={false}
            rowHeight={40}
            headerHeight={40}
            rows={results}
            columns={getColumDefinitions()}
            pageSize={100}
            density={'compact'}
            onFilterModelChange={handleFilterModelCHange}
            filterModel={intitalfilter}
          />
        </div>
      </Loader>
    </Paper>
  )
}

export default OrganizationUsers
