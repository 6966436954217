import { env } from '@rsmus/react-env-image'
import { Get, Put, Delete, timeout } from '../services/http'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiRequestAsync } from '../redux/Actions/actions'
import { HttpRequestState, ApplicationModel } from '../models/core'
import { useHttpErrors } from './useHttpError'

export const useApplications = () => {
  const dispatch = useDispatch()
  const baseUrl = env('REACT_APP_ODATA_BASE_URL')
  const [appRequestStatus, setAppRequestStatus] =
    useState<HttpRequestState>('Idle')
  const [allApps, setAllApps] = useState<ApplicationModel[]>([])
  const { handleNotOkResponse, handleRequestError } = useHttpErrors()

  const getApplications = useCallback(
    async () => {
      if (!baseUrl) {
        return
      }

      try {
        setAppRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Get(`${baseUrl}/api/applications`)
        )
        if (response.ok) {
          const data = await response.json()
          //   data.forEach((brandapp: BrandedApplicationModel) => {
          //     brandapp.id = brandapp.identifier // just need id for the grid and detial edit
          //   })
          setAllApps(data)
          setAppRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setAppRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setAppRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )
  return {
    getApplications,
    setAllApps,
    appRequestStatus,
    allApps,
  }
}
