import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import { ParentOu } from '../../core/models'
import { ConfirmDialog } from '../../core/components/Dialogs/ConfirmDialog'

export interface UserActionProps {
  userId?: string
  status: string
  isFederated: boolean
  type: ParentOu
  onResetPassword: (userId: string) => void
  onResendWelcome: (userId: string) => void
  onUnlock: (userId: string) => void
  canEdit: boolean
}
export const UserAction = ({
  userId,
  status,
  isFederated,
  onResetPassword,
  onResendWelcome,
  onUnlock,
  canEdit,
  type,
}: UserActionProps) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const onActionClickHandle = () => {
    setConfirmDialogOpen(true)
  }
  const onOkClickHandle = () => {
    setConfirmDialogOpen(false)
    switch (status) {
      case 'PROVISIONED': {
        onResendWelcome(userId + '')
        break
      }
      case 'ACTIVE':
      case 'RECOVERY':
      case 'PASSWORD_EXPIRED': {
        onResetPassword(userId + '')
        break
      }
      case 'LOCKED_OUT': {
        onUnlock(userId + '')
      }
    }
  }
  const onCancelHandle = () => {
    setConfirmDialogOpen(false)
  }
  const buttonToolTip = (status: string): string => {
    switch (status) {
      case 'PROVISIONED': {
        return 'The click action will  resend the IDP welcome Email to the user.'
      }
      case 'ACTIVE':
      case 'RECOVERY':
      case 'PASSWORD_EXPIRED': {
        return 'The click action will Send reset password email to the user.'
      }
      case 'LOCKED_OUT': {
        return 'The click action will UnLock the user.'
      }
      default: {
        return ''
      }
    }
  }
  const buttonCaption = (): string => {
    if (type === 'External' && canEdit) {
      switch (status) {
        case 'PROVISIONED': {
          return 'Resend the Activation Email'
        }
        case 'ACTIVE':
        case 'RECOVERY':
        case 'PASSWORD_EXPIRED': {
          return 'Reset Password Email'
        }
        case 'LOCKED_OUT': {
          return 'Unlock Account'
        }
        default: {
          return '' // no button
        }
      }
    }
    return '' // no button
  }
  const confirmDialogTitle = (): string => {
    switch (status) {
      case 'PROVISIONED': {
        return 'Are you sure you want to proceed with resending the activation email.'
      }
      case 'ACTIVE':
      case 'RECOVERY':
      case 'PASSWORD_EXPIRED': {
        return 'Are you sure you want to proceed with sending a reset password email.'
      }
      case 'LOCKED_OUT': {
        return 'Are you sure you want to proceed with unlocking this account.'
      }
      default: {
        return '' // no dialog caption
      }
    }
  }
  if (buttonCaption() !== '') {
    return (
      <div>
        <Button
          style={{
            minWidth: 250, // keeps buttons same size
          }}
          disabled={isFederated}
          variant='contained'
          title={buttonToolTip(status)}
          color='primary'
          onClick={() => {
            onActionClickHandle()
          }}
        >
          {buttonCaption()}
        </Button>
        {confirmDialogOpen && (
          <ConfirmDialog
            title={confirmDialogTitle()}
            isOpen={confirmDialogOpen}
            onOkHandler={onOkClickHandle}
            onCancelHandler={onCancelHandle}
          />
        )}
      </div>
    )
  } else {
    return null
  }
}
