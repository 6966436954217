import { Typography } from '@material-ui/core'
import React from 'react'
import { ApplicationClaim } from '../../core/models'

export interface ClaimsProps {
  claims?: ApplicationClaim[]
}

export const Claims = ({ claims }: ClaimsProps) => {
  if (claims === undefined || claims === null || claims.length === 0) {
    return (
      <React.Fragment>
        <Typography variant='caption'>No Roles Assigned</Typography>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      {claims.length > 0 &&
        claims.map((ac: ApplicationClaim, index: number) => {
          const addComma = claims && index < claims.length - 1
          return (
            <React.Fragment key={ac.id}>
              <span style={{ fontWeight: 600 }}>
                {ac.applicationDescription}:&nbsp;
              </span>
              <span>{ac.description}</span>
              {addComma && <span>{`, `}</span>}
            </React.Fragment>
          )
        })}
      {claims.length <= 0 && (
        <span style={{ fontStyle: 'italic' }}>No Roles Assigned</span>
      )}
    </React.Fragment>
  )
}
