import { FormControl, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useOrganizationApplications } from '../../core/hooks/useOrganizationApplications'
import { Loader } from '../../core/components/Loader'
import { Field, FormikErrors, FormikTouched } from 'formik'
import {
  ApplicationClaimsRequest,
  ApplicationRolesMap,
  ApplicationUserAttributeDefination,
  ApplicationUserAttributeViewModel,
  ApplicationUserAttributeViewModelRequest,
  LookUpItem,
  ParentOu,
} from '../../core/models'
import { UserFormDefinition } from '../users/userForm'

export interface OrganizationApplicationClaimsProps {
  organizationId: string
  parentOu: ParentOu
  touched: FormikTouched<any>
  errors: FormikErrors<any>
  value: ApplicationClaimsRequest
  onChange: (allFieldValues: ApplicationClaimsRequest) => void
  canEdit: boolean
  applicationAndUserAttributes: ApplicationUserAttributeViewModelRequest
  onChangeAppUserAttribute: (
    allFieldValues: ApplicationUserAttributeViewModelRequest,
    fieldName: string,
    fieldValue: string
  ) => void
  values: UserFormDefinition
}

// should simplify contract and use name instead  [Key string]: string[]

export const OrganizationApplicationClaims = ({
  organizationId,
  parentOu,
  touched,
  errors,
  value,
  onChange,
  canEdit,
  applicationAndUserAttributes,
  onChangeAppUserAttribute,
  values,
}: OrganizationApplicationClaimsProps) => {
  const { getApplications, requestStatus, applications } =
    useOrganizationApplications()
  // const classes = useStyles()
  const [optionsMap, setOptionsMap] = useState<ApplicationRolesMap>({})
  const [appUserAttributeDefinations, setAppUserAttributeDefinations] =
    useState<ApplicationUserAttributeDefination[]>([])

  useEffect(() => {
    getApplications(organizationId, parentOu)
  }, [getApplications, organizationId, parentOu])

  useEffect(() => {
    const results: ApplicationRolesMap = {}
    const appUserAttribDefs: ApplicationUserAttributeDefination[] = []
    applications
      .sort((a, b) => {
        // sort to make the  single claims apps list first in the list of auto complete drop downs.
        const result1 = a.allowMultipleClaims ? 1 : 0
        const result2 = b.allowMultipleClaims ? 1 : 0
        const claimresult = (result1 - result2) * 100
        // sort by name
        const nameCompare = String(a.name).localeCompare(b.name)
        const combineResult = nameCompare + claimresult
        return combineResult
      })
      .filter((app) => app.enabled === true)
      .forEach((app) => {
        const items = app.supportedClaims.map((claim): LookUpItem => {
          return {
            key: claim.id,
            displayName: claim.claim,
            description: claim.description,
            enabled: true,
            metadata: {},
          }
        })
        const allowMultipleClaims = app.allowMultipleClaims
        const selectedIds = value[app.id] || []
        const selected = items.filter(
          (i) => selectedIds.indexOf(i.key as number) !== -1
        )

        results[app.name] = { id: app.id, items, selected, allowMultipleClaims }
        if (app.userAttributes.length > 0) {
          app.userAttributes.forEach((attb) => {
            appUserAttribDefs.push(attb)
          })
        }
      })
    setAppUserAttributeDefinations(appUserAttribDefs)
    setOptionsMap(results)
  }, [applications, value])
  const handleApplicationUserAttributesChange = (
    fieldName: string,
    appId: number,
    value: string
  ) => {
    // console.log(fieldName)
    // console.log(appId)
    // console.log(value)

    const currentValue: ApplicationUserAttributeViewModelRequest = {}
    // loop through all the definations
    appUserAttributeDefinations.forEach((def) => {
      if (def.applicationId === appId && def.attributeName === fieldName) {
        const arrayofValues = applicationAndUserAttributes[appId]
        if (arrayofValues) {
          arrayofValues[0].attributeValue = value
          currentValue[appId] = arrayofValues
        } else {
          const userAttribute: ApplicationUserAttributeViewModel = {
            attributeName: fieldName,
            attributeValue: value,
          }
          currentValue[appId] = [userAttribute]
        }
      } else {
        // build the rest of the values.
        const arrayofValues = applicationAndUserAttributes[def.applicationId]
        if (arrayofValues) {
          currentValue[def.applicationId] = arrayofValues
        }
        //else  { // I don thin we need to do anything here
        //   const userAttribute: ApplicationUserAttributeViewModel = {
        //     attributeName: fieldName,
        //     attributeValue: value,
        //   }
      }
    })

    // now update called model
    onChangeAppUserAttribute(currentValue, fieldName, value)
  }
  const handleAutoCompleteChange = (
    field: string,
    identifier: number,
    selectedItems: LookUpItem[]
  ) => {
    //console.log('handleAutoCompleteChange')
    const currentValue: ApplicationClaimsRequest = {}

    const getClaimIds = (items: LookUpItem[]) => {
      return items
        .filter((i) => i.key !== undefined && i.key !== '')
        .map((i) => parseInt((i.key as string) || ''))
    }

    const updated: ApplicationRolesMap = {}
    Object.keys(optionsMap).forEach((k) => {
      const { id, items, selected, allowMultipleClaims } = optionsMap[k]
      // if it is the one that changed.
      if (k === field) {
        if (selectedItems.length > 1 && !allowMultipleClaims) {
          //remove first.  last  contain the newly selected item
          selectedItems.splice(0, 1)
        }
        updated[k] = {
          id,
          items,
          selected: selectedItems,
          allowMultipleClaims,
        }
        // not the one changeing but still need to update
      } else {
        updated[k] = {
          id,
          items,
          selected: selected,
          allowMultipleClaims,
        }
      }
      currentValue[id] = getClaimIds(updated[k].selected)
    })

    setOptionsMap(updated)
    onChange(currentValue)
  }
  // We need to  find the applicaion userDefinaiton attribute based on the app id.
  const findApplicationtUserAttributeDef = (appId: number) => {
    const positon = applications.findIndex((x) => x.id === appId)
    // for now just return first
    if (positon > -1) {
      if (applications[positon].userAttributes.length > 0) {
        return applications[positon].userAttributes[0]
      }
    }
    return null
  }
  const ready = requestStatus === 'Success'
  if (requestStatus === 'Failed' || requestStatus === 'Unauthorized') {
    // we dont want a spinning if  the reques fails just return else endless spinner.
    return null
  }
  /// Validation of Applicaiotn Defined Attributes.
  const validateUserDefined = (
    value: string,
    applicationUserAttributeDefination: ApplicationUserAttributeDefination,
    selected: LookUpItem[]
  ) => {
    let err = undefined
    // if app claim/role has a role selected.
    if (selected && selected.length > 0) {
      // if required.
      if (applicationUserAttributeDefination.required) {
        if (value && value.trim()) {
          err = undefined
        } else {
          err = 'A value is required'
        }
      }
      if (applicationUserAttributeDefination.attributeMaxLength > 0) {
        if (value && value.trim() && !err) {
          // length is ok then return undefined.
          if (
            value &&
            value.trim().length <=
              applicationUserAttributeDefination.attributeMaxLength
          ) {
            err = undefined
          } else {
            err = `Maximum length of the attribute is ${applicationUserAttributeDefination.attributeMaxLength} `
          }
        }
      }
      if (applicationUserAttributeDefination.attributeMinLength > 0) {
        if (value && value.trim() && !err) {
          // length is ok then return undefined.
          if (
            value &&
            value.trim().length >=
              applicationUserAttributeDefination.attributeMinLength
          ) {
            err = undefined
          } else {
            err = `Minimum length of the attribute is ${applicationUserAttributeDefination.attributeMinLength}`
          }
        }
      }
    } // if any validation needed.
    return err
  }

  return (
    <Loader ready={ready}>
      {Object.keys(optionsMap).map((key) => {
        const {
          selected,
          items: options,
          id,
          allowMultipleClaims,
        } = optionsMap[key]
        const appuserattributedefine = findApplicationtUserAttributeDef(id)
        var appuserattributeValue: ApplicationUserAttributeViewModel | undefined
        if (appuserattributedefine) {
          //console.log(applicationAndUserAttributes)
          const arrayofValues =
            applicationAndUserAttributes[appuserattributedefine.applicationId]

          if (arrayofValues && arrayofValues.length > 0) {
            // just get the first one.
            appuserattributeValue = arrayofValues[0]
          }
        }

        return (
          <div>
            <FormControl>
              <Autocomplete
                disabled={!canEdit}
                multiple
                id={key}
                getOptionSelected={(option, value) => {
                  if (option && option.key && value && value.key) {
                    return option.key === value.key
                  } else {
                    return false
                  }
                }}
                value={selected}
                options={options}
                getOptionLabel={(option) => option.description || ''}
                // defaultValue={[]}
                onChange={(event: any, value: any) =>
                  handleAutoCompleteChange(key, id, value as LookUpItem[])
                }
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={key}
                    variant='outlined'
                    key={key}
                    label={key}
                    placeholder={allowMultipleClaims ? 'Role(s)' : 'Role'}
                    helperText={touched[key] ? errors[key] : ''}
                    error={touched[key] && Boolean(errors[key])}
                  />
                )}
              />
            </FormControl>
            {appuserattributedefine && (
              <FormControl style={{ marginLeft: 10 }}>
                <Field
                  component={TextField}
                  disabled={!canEdit || selected.length === 0}
                  id={appuserattributedefine.attributeName}
                  name={appuserattributedefine.attributeName}
                  key={appuserattributedefine.attributeName}
                  validate={(value: string) => {
                    // attempt manual validation.
                    return validateUserDefined(
                      value,
                      appuserattributedefine,
                      selected
                    )
                  }}
                  variant='outlined'
                  label={appuserattributedefine.attributeDisplayName} // id is app id key is applicaton name/descriptin
                  required={appuserattributedefine.required}
                  autoComplete='off'
                  //value={values[appuserattributedefine.attributeName]}
                  value={appuserattributeValue?.attributeValue}
                  //type={'number'}
                  onChange={(event: any) =>
                    handleApplicationUserAttributesChange(
                      appuserattributedefine.attributeName,
                      id,
                      event.target.value
                    )
                  }
                  placeholder={appuserattributedefine.attributeDisplayName}
                  helperText={
                    Boolean(errors[appuserattributedefine.attributeName])
                      ? errors[appuserattributedefine.attributeName]
                      : appuserattributedefine.attributeDescription
                  }
                  error={Boolean(errors[appuserattributedefine.attributeName])}
                />
              </FormControl>
            )}
          </div>
        )
      })}
    </Loader>
  )
}
