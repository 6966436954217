import { createReducer } from 'typesafe-actions'
import { HttpRequestState } from '../../models/core'
import { Actions, apiRequestAsync } from '../Actions/actions'

export interface ApiRequestState {
  requestState: HttpRequestState
  error?: Error
}

export const apiRequestReducer = createReducer<ApiRequestState, Actions>({
  requestState: 'Idle',
})
  .handleAction(apiRequestAsync.request, (state, action) => ({
    ...state,
    requestState: 'InProgress',
  }))
  .handleAction(apiRequestAsync.success, (state, action) => ({
    ...state,
    requestState: 'Success',
  }))
  .handleAction(apiRequestAsync.failure, (state, action) => ({
    ...state,
    requestState: 'Failed',
    error: action.payload,
  }))
