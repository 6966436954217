import { IconButton, Tooltip } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import React from 'react'

export interface DownloadButtonProps {
  toolTip: string
  handleDownLoad: () => void
}

export const DownloadButton = ({
  toolTip,
  handleDownLoad,
}: DownloadButtonProps) => {
  return (
    <Tooltip title={toolTip} placement='top-start'>
      <IconButton aria-label={toolTip} color='inherit' onClick={handleDownLoad}>
        <GetApp />
      </IconButton>
    </Tooltip>
  )
}
