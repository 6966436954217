import React from 'react'

import { Loader } from '../../core/components/Loader'
import {
  ApplicationUserAttributeViewModelRequest,
  CreateUserRequest,
  UpdateUserRequest,
} from '../../core/models'
import UserForm from './userForm'
import { useUsers } from '../../core/hooks/useUsers'
import { useParams } from 'react-router-dom'
import { ensureNumber } from '../../core/services/guards'

const UserCreate = () => {
  const { id: organizationId } = useParams<{
    id: string
  }>()

  const { createUser, requestStatus } = useUsers()

  const handleCreateRequest = (request: UpdateUserRequest) => {
    const {
      organizationId,
      applicationClaims,
      applicationAndUserAttributes,
      fields: {
        firstName = '',
        lastName = '',
        email = '',
        title,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        rsmEulaAccepted = false,
      },
    } = request
    const modifiedapplicationAndUserAttributes: ApplicationUserAttributeViewModelRequest =
      {}
    Object.keys(request.applicationClaims).forEach((k) => {
      const claims = request.applicationClaims[k]
      // only move to output if there are claims for the application.
      if (applicationAndUserAttributes[ensureNumber(k)] && claims.length > 0) {
        modifiedapplicationAndUserAttributes[ensureNumber(k)] =
          applicationAndUserAttributes[ensureNumber(k)]
      }
    })
    const createRequest: CreateUserRequest = {
      firstName,
      lastName,
      email,
      title,
      organizationId,
      applicationClaims,
      applicationAndUserAttributes: { ...modifiedapplicationAndUserAttributes },
    }

    createUser(createRequest)
  }

  const ready = requestStatus !== 'InProgress'
  if (requestStatus === 'Failed' || requestStatus === 'Unauthorized') {
    // we dont want a spinning if  the request fails just return else endless spinner.
    return null
  }

  return (
    <Loader ready={ready}>
      <UserForm
        user={{
          lastName: '',
          firstName: '',
          email: '',
          title: '',
          parentOu: 'External',
          sepRsmuid: '',
          applicationClaims: {},
          rsmEulaAccepted: false,
          applicationAndUserAttributes: {},
        }}
        organizationId={organizationId}
        onSave={handleCreateRequest}
        canEdit={true}
        callerAction={'Create'}
      />
    </Loader>
  )
}

export default UserCreate
