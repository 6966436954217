import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import { AlertMessage, PopAlert } from '../../redux/Actions/actions'
import { isError, isHttpError } from './utils'
import { NavLink } from 'react-router-dom'
import Alert from '@material-ui/lab/Alert'
enum Severity {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}
const AppAlert = () => {
  const dispatch = useDispatch()
  const { alerts } = useSelector((state: AppState) => state.appContainer)
  const visible = alerts.length > 0
  const alert = visible ? alerts[0] : undefined
  // Guard condiiton  right of the bat
  if (!visible) {
    return null
  }

  const handleClick = () => {
    if (alerts) {
      dispatch(PopAlert())
    }
  }

  const shouldRedirect = (alert: AlertMessage | undefined): boolean => {
    if (alert === undefined) {
      return false
    }

    let shouldRedirect = false
    if (isHttpError(alert)) {
      const status = alert.status || 0
      if (status === 401 || status === 404 || status === 500) {
        shouldRedirect = true
      }
    }

    return shouldRedirect
  }
  const shouldRedirectFlag = shouldRedirect(alert)

  // const isWarning = (alert: AlertMessage | undefined): boolean => {
  //   if (alert === undefined) {
  //     return false
  //   }

  //   return isError(alert)
  // }
  const getSeverity = (alert: AlertMessage | undefined): Severity => {
    if (alert === undefined) {
      return Severity.Info
    }
    if (isError(alert)) {
      return Severity.Warning
    } else {
      return Severity.Success
    }
  }

  const serverity = getSeverity(alert)
  const text = isError(alert) ? alert.message : alert

  return (
    visible && (
      <Alert onClose={handleClick} severity={serverity}>
        <div dangerouslySetInnerHTML={{ __html: text + '' }}></div>
        <div>
          {shouldRedirectFlag && (
            <NavLink to='/' exact={true} onClick={handleClick}>
              <div>Return Home</div>
            </NavLink>
          )}
        </div>
      </Alert>
    )
  )
  //---------------- old way-------------
  // return (
  //   visible && (
  //     <div className={`alert-container ${style}`} onClick={handleClick}>
  //       <div>{text || ''}</div>
  //       <div>
  //         {shouldRedirectFlag && (
  //           <NavLink to='/' exact={true} onClick={handleClickForRedirect}>
  //             <div>Return Home</div>
  //           </NavLink>
  //         )}
  //       </div>
  //     </div>
  //   )
  // )
}

export default AppAlert
