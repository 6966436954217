export function formatClientId(clientId?: string | number) {
  if (clientId === undefined) {
    return clientId
  }

  let id = clientId + ''
  while (id.length < 7) {
    id = `0${id}`
  }
  return id
  // removed the dashs for the ids
  // const parts = id.match(/(\d{3})(\d{3})(\d{1})/)
  // if (parts && parts !== undefined) {
  //   return `${parts[1]}-${parts[2]}-${parts[3]}`
  // } else {
  //   return id
  // }
}
export function formatMasterAndClientId(
  masterId?: string | number,
  clientId?: string | number
) {
  const masterAndClientid =
    formatClientId(masterId) + '-' + formatClientId(clientId)
  return masterAndClientid
}

const dateTimeFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
})

export const formatDate = (date: Date | undefined) => {
  if (!date) {
    return ''
  }

  const dateValue = Date.parse(date as any)
  return dateTimeFormatter.format(dateValue)
}
