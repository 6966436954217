import { AlertMessage } from '../../redux/Actions'
import { HttpError } from '../../services/httpError'

export function isHttpError(
  message: AlertMessage | undefined
): message is HttpError {
  if (message === undefined) {
    return false
  }

  return (message as HttpError).status !== undefined
}

export function isError(message: AlertMessage | undefined): message is Error {
  if (message === undefined) {
    return false
  }

  return (message as Error).message !== undefined
}
