import { Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { Loader } from '../../core/components/Loader'
import { useOrganizationUser } from '../../core/hooks/useOrganizationUser'
import { useBrandedApplications } from '../../core/hooks/useBrandedApplications'
import { FindUserButton } from './findUserButton'
import UserViewApp from './userViewApp'
import UserViewBrandedApplications from './userViewBrandedApplications'
import UserViewOrgApp from './userViewOrgApp'
import UserViewUser from './userViewUser'

const UserView = () => {
  const { id } = useParams<{ id: string }>() // the user ID
  const { getUserView, userView, requestStatus } = useOrganizationUser()
  const {
    getUserAuthorizedBrandedApplications,
    allBrandedApps,
    baRequestStatus,
  } = useBrandedApplications()

  useEffect(() => {
    // scene will launch with out an id selected.
    if (id) {
      getUserView(id)
    }
  }, [getUserView, id])

  useEffect(() => {
    // scene will launch with out an id selected.
    if (id) {
      getUserAuthorizedBrandedApplications(id)
    }
  }, [getUserAuthorizedBrandedApplications, id])
  const ready =
    (requestStatus === 'Success' || requestStatus === 'Idle') &&
    (baRequestStatus === 'Success' || baRequestStatus === 'Idle')

  if (requestStatus === 'Failed' || requestStatus === 'Unauthorized') {
    // we dont want a spinning if  the request fails just return else endless spinner.
    return null
  }

  return (
    <React.Fragment>
      <div>
        <Typography variant='h3'>Search Users</Typography>
        <FindUserButton />
      </div>
      <Loader ready={ready}>
        {userView && (
          <div>
            <UserViewUser user={userView.user} />
            <div> </div>
            <UserViewOrgApp
              orgApps={userView.organizationApplications}
              userId={userView.user.rsmUid}
            />
            <UserViewApp
              userApps={userView.userApplications}
              userId={userView.user.rsmUid}
            />
            <UserViewBrandedApplications
              userApps={allBrandedApps}
              userId={userView.user.rsmUid}
            />
          </div>
        )}
      </Loader>
    </React.Fragment>
  )
}

export default UserView
