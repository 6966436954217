import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { fade, Theme, withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'

import PersonAdd from '@material-ui/icons/PersonAdd'
import {
  Toolbar,
  Paper,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  TextField,
} from '@material-ui/core'
// import { SearchIcon } from '@material-ui/data-grid'
import {
  AddCircle as AddIcon,
  AddRounded as CreateIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'
import { TsaTheme } from '../../core/styles/tsaTheme'
import { debounce } from 'lodash'
import { useUsers } from '../../core/hooks/useUsers'
import { ParentOu } from '../../core/models'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles((theme: typeof TsaTheme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addIcon: {
    padding: theme.spacing(0, 2),
    // height: '100%',
    // position: 'absolute',
    // pointerEvents: 'none',
    // display: 'flex',
    alignItems: 'right',
    justifyContent: 'right',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  results: {
    display: 'flex',
    minHeight: 200,
    width: '100%',
    overflowY: 'visible',
  },
}))

export interface DialogTitleProps {
  id: string
  children: React.ReactNode
  onClose: () => void
}

export const DialogTitle = ({
  children,
  onClose,
  ...other
}: DialogTitleProps) => {
  const classes = useStyles()

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)
export interface SearchUsersButtonProps {
  organizationId: string
  canAddEditRsmUser: boolean
}
export const SearchUsersButton = ({
  organizationId,
  canAddEditRsmUser,
}: SearchUsersButtonProps) => {
  const classes = useStyles()

  const { searchUsers, results } = useUsers()
  const [open, setOpen] = React.useState(false)
  const [query, setQuery] = useState<string>()
  const [parentOu, setParentOu] = useState<ParentOu>('External')
  const history = useHistory()
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  // eslint-disable-next-line
  const debouncedChangeQuery = useCallback(
    debounce(
      (value: string, parentOu: ParentOu) =>
        searchUsers({ organizationId, context: parentOu, query: value }),
      500
    ),
    []
  )

  const handleChangeQuery = (event: any) => {
    const value = event.target.value
    setQuery(value)
    debouncedChangeQuery(value, parentOu)
  }
  const handleOnClick = (event: any, id: string) => {
    history.push(`/organizations/${organizationId}/users/assign/${id}`)
  }

  function handleChangeContext(event: React.ChangeEvent<HTMLInputElement>) {
    const internalExternal: ParentOu = (event.target as HTMLInputElement)
      .value as ParentOu
    setParentOu(internalExternal)
    debouncedChangeQuery(query + '', internalExternal)
    // we also need to refresh the search
  }

  return (
    <React.Fragment>
      <Tooltip title='Create or Assign User' placement='top-start'>
        <IconButton
          aria-label='Add User'
          color='inherit'
          onClick={handleClickOpen}
        >
          <PersonAdd />
        </IconButton>
      </Tooltip>
      <Dialog
        style={{ maxHeight: 800, minHeight: 600 }}
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          Add User
        </DialogTitle>
        <DialogContent dividers>
          <Toolbar>
            <div className={classes.search}>
              {/* <div className={classes.searchIcon}>
                <SearchIcon />
              </div> */}
              <TextField
                id='filled-search'
                label='Search...'
                type='search'
                variant='filled'
                value={query}
                onChange={handleChangeQuery}
              />
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <NavLink
                to={`/organizations/${organizationId}/users/create`}
                exact={true}
              >
                <Tooltip title='Create  User' placement='top-start'>
                  <IconButton aria-label='create user' color='inherit'>
                    <CreateIcon />
                  </IconButton>
                </Tooltip>
              </NavLink>
            </div>
          </Toolbar>

          <Paper className={classes.results}>
            <List>
              {results.map((u) => {
                const {
                  lastName,
                  firstName,
                  email,
                  existsInOrganization,
                  id,
                  status,
                } = u
                const displayName = `${lastName}, ${firstName}`
                if (existsInOrganization) {
                  return (
                    <ListItem disabled={true} button>
                      <ListItemIcon>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText primary={displayName} secondary={email} />
                    </ListItem>
                  )
                }
                if (status === 'DEPROVISIONED') {
                  return (
                    <ListItem disabled={true} button>
                      <ListItemIcon>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={displayName + '(' + status + ')'}
                        secondary={email}
                      />
                    </ListItem>
                  )
                }
                return (
                  <ListItem button onClick={(e: any) => handleOnClick(e, id)}>
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary={displayName} secondary={email} />
                  </ListItem>
                )
              })}
            </List>
          </Paper>
        </DialogContent>
        <DialogActions>
          <FormControl component='fieldset'>
            <RadioGroup
              row
              aria-label='position'
              name='position'
              value={parentOu}
              onChange={handleChangeContext}
            >
              <FormControlLabel
                value={'External'}
                control={<Radio color='primary' />}
                label='External'
              />
              {canAddEditRsmUser && (
                <FormControlLabel
                  value={'Internal'}
                  control={<Radio color='primary' />}
                  label='Internal'
                />
              )}
            </RadioGroup>
          </FormControl>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
