import { createMuiTheme } from '@material-ui/core'
import { theme as tsaStyles } from '@rsmus/react-ui/dist/theme'

export const TsaTheme = createMuiTheme({
  typography: {
    ...tsaStyles.typogrphaphy,
    fontFamily: 'Prelo-Book',
  },
  palette: {
    primary: tsaStyles.palette.blue,
    ...tsaStyles.palette,
  },
})
