import { env } from '@rsmus/react-env-image'
import { Get, timeout } from '../services/http'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiRequestAsync } from '../redux/Actions/actions'
import { HttpRequestState } from '../models/core'
import { useHttpErrors } from './useHttpError'
// let controller = new AbortController()

export const useFileDownload = () => {
  const dispatch = useDispatch()
  // const { signal } = controller
  const baseUrl = env('REACT_APP_ODATA_BASE_URL')
  const [requestStatus, setRequestStatus] = useState<HttpRequestState>('Idle')
  const { handleNotOkResponse, handleRequestError } = useHttpErrors()
  const saveFile = (response: Blob, filename: string) => {
    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
      window.navigator.msSaveBlob(response, filename)
    } else {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(response)
      link.download = filename
      document.body.appendChild(link)
      link.click()
    }
  }

  const downloadFile = useCallback(async () => {
    if (!baseUrl) {
      return
    }

    try {
      setRequestStatus('InProgress')
      dispatch(apiRequestAsync.request)
      const response = await timeout(
        30000,
        Get(`${baseUrl}/api/files/template`)
      )
      if (response.ok) {
        const blob = await response.blob()
        saveFile(blob, 'UsersImportTemplate.xlsx')
        setRequestStatus('Success')
        dispatch(apiRequestAsync.success)
      } else {
        setRequestStatus('Failed')
        await handleNotOkResponse(response)
      }
    } catch (error) {
      setRequestStatus('Failed')
      handleRequestError(error as Error)
    }
    // eslint-disable-next-line
  }, [baseUrl, dispatch])
  const downloadNewTemplate = useCallback(async () => {
    if (!baseUrl) {
      return
    }

    try {
      setRequestStatus('InProgress')
      dispatch(apiRequestAsync.request)
      const response = await timeout(
        30000,
        Get(`${baseUrl}/api/files/templatenew`)
      )
      if (response.ok) {
        const blob = await response.blob()
        saveFile(blob, 'IDMUserUploadTemplate.xlsx')
        setRequestStatus('Success')
        dispatch(apiRequestAsync.success)
      } else {
        setRequestStatus('Failed')
        await handleNotOkResponse(response)
      }
    } catch (error) {
      setRequestStatus('Failed')
      handleRequestError(error as Error)
    }
    // eslint-disable-next-line
  }, [baseUrl, dispatch])

  return { downloadNewTemplate, downloadFile, requestStatus }
}
