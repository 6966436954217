import { env } from '@rsmus/react-env-image'
import { Get, timeout } from '../services/http'

import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiRequestAsync } from '../redux/Actions/actions'
import { HttpRequestState, Organization } from '../models/core'
import { SearchRequest } from '../models'
import { useHttpErrors } from './useHttpError'
import { formatMasterAndClientId } from '../services'
// let controller = new AbortController()

export const useOrganizations = () => {
  const dispatch = useDispatch()
  // const { signal } = controller
  const baseUrl = env('REACT_APP_ODATA_BASE_URL') // + '/invalid'

  const [requestStatus, setRequestStatus] = useState<HttpRequestState>('Idle')
  const [results, setResults] = useState<Organization[]>([])
  const { handleNotOkResponse, handleRequestError } = useHttpErrors()

  const getMyOrganizations = useCallback(async () => {
    if (!baseUrl) {
      return
    }

    try {
      setRequestStatus('InProgress')
      dispatch(apiRequestAsync.request)
      const response = await timeout(
        30000,
        Get(`${baseUrl}/api/users/organizations`)
      )
      if (response.ok) {
        const data = await response.json()
        data.forEach((org: Organization) => {
          org.masterAndClientId = formatMasterAndClientId(
            org.masterClientNumber,
            org.id
          )
        })
        setResults(data)
        setRequestStatus('Success')
        dispatch(apiRequestAsync.success)
      } else {
        setRequestStatus('Failed')
        await handleNotOkResponse(response)
      }
    } catch (error) {
      setRequestStatus('Failed')
      handleRequestError(error)
    }
    // eslint-disable-next-line
  }, [baseUrl, dispatch])

  const searchOrganizations = useCallback(
    async (request: SearchRequest) => {
      if (!baseUrl) {
        return
      }

      if (request.query === undefined || request.query === '') {
        setResults([])
        setRequestStatus('Success')
        dispatch(apiRequestAsync.success)
        return
      }

      try {
        setRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Get(`${baseUrl}/api/organizations?filter=${request.query}`)
        )
        if (response.ok) {
          const data = await response.json()
          data.forEach((org: Organization) => {
            org.masterAndClientId = formatMasterAndClientId(
              org.masterClientNumber,
              org.id
            )
          })
          setResults(data)
          setRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setRequestStatus('Failed')
        dispatch(apiRequestAsync.failure(error))
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )

  return { getMyOrganizations, searchOrganizations, requestStatus, results }
}
