import { useContext, useCallback } from 'react'
import { OidcIdentityContext } from '@rsmus/react-auth'
import { ActiveUserProfile } from '../models'
//import { User } from 'oidc-client-ts'
import { env } from '@rsmus/react-env-image'

export const useRoles = () => {
  const ROLE_ADMIN = 'RSMAdmin'
  const ROLE_CLIENT_ADMIN = 'ClientAdmin'
  // const ROLE_CLIENT_USER = 'ClientUser'
  // const ROLE_THIRD_PARTY_USER = 'ThirdPartyUser'
  // const ROLE_RSM_ADMIN_DESC = 'RSM Admin'
  // const ROLE_RSM_USER_DESC = 'RSM User'
  // const ROLE_CLIENT_ADMIN_DESC = 'Client Admin'
  // const ROLE_CLIENT_USER_DESC = 'Client User'
  // const ROLE_THIRD_PARTY_USER_DESC = 'Third Party User'
  const ROLE_SERVICEDESK_DN = 'IDM Admin Service Desk Group'
  //const ROLE_SERVICEDESK_DN = 'NOTFOUND Group'

  const { hasRoleInOrg, user } = useContext(OidcIdentityContext)

  const checkIfExternal = useCallback((userGroupsClaim: string | string[]) => {
    const INTERNAL_USERS_GROUPS_VALUE =
      env('REACT_APP_IDM_INTERNAL_USERS_GROUPS') || 'Internal Users'
    const allGroups = INTERNAL_USERS_GROUPS_VALUE.split('|')

    if (Array.isArray(userGroupsClaim)) {
      let result = true
      for (const i in userGroupsClaim) {
        const claim = userGroupsClaim[i]
        const isInternal = allGroups.indexOf(claim) !== -1
        if (isInternal) {
          result = false
          break
        }
      }
      return result
    } else {
      return allGroups.indexOf(userGroupsClaim) === -1
    }
  }, [])

  const getActiveUser = useCallback((): ActiveUserProfile | undefined => {
    let userProfile: ActiveUserProfile
    if (!user) {
      return undefined
    }
    //const { id_token, access_token, profile } = user
    const { access_token, profile } = user
    //const parsedIdToken = getTokenInfo(id_token)
    const parsedAccessToken = getTokenInfo(access_token)

    userProfile = {
      email: profile.preferred_username,
      firstName: profile.given_name,
      lastName: profile.family_name,
      name: profile.given_name + ', ' + profile.family_name,
      uniqueId: profile.rsmuid as string,
      exp: parsedAccessToken.exp,
      isExternal: checkIfExternal(parsedAccessToken.groups),
      groups: parsedAccessToken.groups,
      applications: parsedAccessToken.cid,
    }

    return userProfile
  }, [user, checkIfExternal])

  const getTokenInfo = (token: string): any => {
    const splitToken = token.split('.')
    let payload = splitToken[1].replace('-', '+').replace('_', '/')
    payload = payload.padEnd(
      payload.length + ((4 - (payload.length % 4)) % 4),
      '='
    )
    return JSON.parse(window.atob(payload))
  }
  const isAdministrator = useCallback(
    (organization: number) => {
      if (
        hasRoleInOrg(ROLE_ADMIN, organization) ||
        hasRoleInOrg(ROLE_CLIENT_ADMIN, organization)
      ) {
        return true
      }
      return false
    },
    [hasRoleInOrg]
  )
  const isSuperAdmin = useCallback((): boolean => {
    const activeUser = getActiveUser()
    if (!activeUser) return false
    const isServiceDesk = activeUser.groups.find(
      (g) => g.toLocaleLowerCase() === ROLE_SERVICEDESK_DN.toLocaleLowerCase()
    )
    if (isServiceDesk) {
      return true
    } else {
      return false
    }
  }, [getActiveUser])
  const isAdministratorOrSuperuser = useCallback(
    (organization: number): boolean => {
      const activeUser = getActiveUser()
      if (!activeUser) return false
      const isServiceDesk = activeUser.groups.find(
        (g) => g.toLocaleLowerCase() === ROLE_SERVICEDESK_DN.toLocaleLowerCase()
      )
      if (isServiceDesk) {
        return true
      }

      return isAdministrator(organization)
    },
    [getActiveUser, isAdministrator]
  )
  return {
    isAdministrator,
    isAdministratorOrSuperuser,
    isSuperAdmin,
    getActiveUser,
  }
}
