import { getAccessToken } from '@rsmus/react-auth'

export const getApiHeader = async (accessToken?: string): Promise<Headers> => {
  const token = accessToken === undefined ? await getAccessToken() : accessToken
  const forceErr: boolean = false
  const headers = new Headers()
  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')
  if (forceErr) {
    headers.append('Authorization', `Bearer ${token + 'invalid'}`)
  } else {
    if (token) {
      headers.append('Authorization', `Bearer ${token}`)
    }
  }

  return headers
}

export const buildHttpRequest = async (
  url: string,
  method: string,
  data?: any
): Promise<Request> => {
  const headers = await getApiHeader()
  const config = data
    ? {
        method,
        body: JSON.stringify(data),
        headers,
        cache: 'no-cache',
        mode: 'cors',
      }
    : {
        method,
        headers,
        cache: 'no-cache',
        mode: 'cors',
      }

  return new Request(url, config as RequestInit)
}

export function timeout(
  ms: number,
  promise: Promise<Response>
): Promise<Response> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('request timeout'))
    }, ms)
    promise.then(resolve, reject)
  })
}

export const Get = async (url: string): Promise<Response> => {
  const request = await buildHttpRequest(url, 'GET')
  return fetch(request)
}

export const Post = async (url: string, data?: any): Promise<Response> => {
  const request = await buildHttpRequest(url, 'POST', data)
  return fetch(request)
}

export const Put = async (url: string, data?: any): Promise<Response> => {
  const request = await buildHttpRequest(url, 'PUT', data)
  return fetch(request)
}

export const Patch = async (url: string, data?: any): Promise<Response> => {
  const request = await buildHttpRequest(url, 'PATCH', data)
  return fetch(request)
}

export const Delete = async (url: string, data?: any): Promise<Response> => {
  const request = await buildHttpRequest(url, 'DELETE', data)
  return fetch(request)
}

export const Upload = async (url: string, files: File[]): Promise<Response> => {
  const token = await getAccessToken()
  const headers = new Headers()
  headers.append('Accept', 'application/json')
  headers.append('Content-Type', files[0].type)
  if (token) {
    headers.append('Authorization', `Bearer ${token}`)
  }

  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files', file)
  })

  const config: RequestInit = {
    method: 'POST',
    body: formData,
    headers,
    cache: 'no-cache',
    mode: 'cors',
  }

  const request = new Request(url, config)

  return fetch(request)
}
