import { useDispatch } from 'react-redux'
import { apiRequestAsync, PushAlert } from '../redux/Actions/actions'
import { HttpError } from '../services/httpError'
export const useHttpErrors = () => {
  const dispatch = useDispatch()
  const handleNotOkResponse = async (resp: Response) => {
    let myHttpErr: HttpError | undefined = undefined
    if (resp.status === 400) {
      const response = await resp.json()
      myHttpErr = new HttpError(response, 400)
    } else if (resp.status === 401 || resp.status === 403) {
      myHttpErr = new HttpError(
        'You are not authorized to access this page. Please contact RSM support staff for assistance.',
        401
      )
    } else if (resp.status === 404) {
      myHttpErr = new HttpError(
        'The requested page could not be found. Please contact RSM support staff for assistance.',
        404
      )
    } else if (resp.status === 409) {
      const response = await resp.json()
      myHttpErr = new HttpError(response.value, 409)
    } else if (resp.status < 200 || resp.status > 299) {
      myHttpErr = new HttpError(
        'An error occurred when communicating to the service. Please contact RSM support staff for assistance.',
        resp.status
      )
    }
    if (myHttpErr) {
      dispatch(PushAlert(myHttpErr))
      dispatch(apiRequestAsync.failure(myHttpErr))
      return myHttpErr
    } else {
      return new HttpError(
        'An error occurred when communicating to the service. Please contact RSM support staff for assistance.',
        resp.status
      )
    }
  }
  const handleRequestError = (err: Error) => {
    dispatch(PushAlert(err))
    dispatch(apiRequestAsync.failure(err))
  }

  return { handleNotOkResponse, handleRequestError }
}
