import { env } from '@rsmus/react-env-image'
import { Get, Patch, Put, timeout } from '../services/http'

import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiRequestAsync, PushAlert } from '../redux/Actions/actions'
import { HttpRequestState, User } from '../models/core'
import { ApplicationClaim, UpdateUserRequest, UserViewModel } from '../models'
import { useHttpErrors } from './useHttpError'
import { formatMasterAndClientId } from '../services'
// let controller = new AbortController()

export const useOrganizationUser = () => {
  const dispatch = useDispatch()
  // const { signal } = controller
  const baseUrl = env('REACT_APP_ODATA_BASE_URL')

  const [requestStatus, setRequestStatus] = useState<HttpRequestState>('Idle')
  const [user, setUser] = useState<User>()
  const [userView, setUserView] = useState<UserViewModel>()
  const { handleNotOkResponse, handleRequestError } = useHttpErrors()

  const getUser = useCallback(
    async (organizationId: string, userId: string) => {
      if (!baseUrl) {
        return
      }

      try {
        setRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Get(`${baseUrl}/api/organizations/${organizationId}/users/${userId}`)
        )
        if (response.ok) {
          const data = await response.json()
          setUser(data)
          setRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )
  const getUserView = useCallback(
    async (userId: string) => {
      if (!baseUrl) {
        return
      }

      try {
        setRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Get(`${baseUrl}/api/users/${userId}/userviewmodel`)
        )
        if (response.ok) {
          const data: UserViewModel = await response.json()
          // we need an id in the grid
          data.organizationApplications.forEach((orgapp) => {
            orgapp.id = orgapp.organization.id
            orgapp.masterAndClientId = formatMasterAndClientId(
              orgapp.organization.masterClientNumber,
              orgapp.organization.id
            )
            orgapp.orgName = orgapp.organization.name
            if (orgapp.claims && orgapp.claims.length > 0) {
              orgapp.appAndClaims = orgapp.claims
                .map((ac: ApplicationClaim) => {
                  return ac.applicationDescription + ':' + ac.description + ' '
                })
                .join(',')
            } else {
              orgapp.appAndClaims = 'No Roles Assigned'
            }
          })
          // flatten application roles
          data.userApplications.forEach((userApp) => {
            userApp.roles.forEach((role) => {
              userApp.roleDescriptions = userApp.roleDescriptions
                ? userApp.roleDescriptions + ', ' + role
                : role
            })
          })
          setUserView(data)
          setRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )

  const saveUser = useCallback(
    async (request: UpdateUserRequest) => {
      //user.applicationClaims = request.applicationClaims
      //setUser(user)
      if (!baseUrl) {
        return
      }

      try {
        setRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Put(`${baseUrl}/api/users`, request)
        )
        if (response.ok) {
          setRequestStatus('Success')
          dispatch(PushAlert(`Sucessfully Updated User`))
          dispatch(apiRequestAsync.success)
          //getUser(request.organizationId, request.id)
        } else {
          setRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )
  const saveUserStatus = useCallback(
    async (userId: string, organizationId: string, active: boolean) => {
      if (!baseUrl) {
        return
      }

      try {
        setRequestStatus('InProgress')
        dispatch(apiRequestAsync.request)
        const response = await timeout(
          30000,
          Patch(
            `${baseUrl}/api/organizations/${organizationId}/users/${userId}`,
            { active }
          )
        )
        if (response.ok) {
          setRequestStatus('Success')
          dispatch(apiRequestAsync.success)
        } else {
          setRequestStatus('Failed')
          await handleNotOkResponse(response)
        }
      } catch (error) {
        setRequestStatus('Failed')
        handleRequestError(error as Error)
      }
    },
    // eslint-disable-next-line
    [baseUrl, dispatch]
  )

  return {
    getUser,
    saveUserStatus,
    saveUser,
    requestStatus,
    user,
    setUser,
    userView,
    setUserView,
    getUserView,
  }
}
