import { AppBar, Checkbox, makeStyles, Paper, Toolbar } from '@material-ui/core'
import {
  DataGrid,
  GridApi,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from '@material-ui/data-grid'
import { Edit } from '@material-ui/icons'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Claims } from '.'
import { DownloadButton } from '../../core/components/Download'
import { ApplicationClaim, OrgAppViewModel } from '../../core/models'
import { formatClientId } from '../../core/services'
import { TsaTheme } from '../../core/styles/tsaTheme'
const useStyles = makeStyles((theme: typeof TsaTheme) => ({
  root: {
    margin: theme.spacing(0, 0),
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  grow: {
    flexGrow: 1,
  },
  ManageUserHeading: {
    fontSize: 22,
  },
}))
export interface UserViewOrgAppProps {
  orgApps: OrgAppViewModel[]
  userId: string
}
const UserViewOrgApp = ({ orgApps, userId }: UserViewOrgAppProps) => {
  const classes = useStyles()
  var apiRef: GridApi | undefined = undefined
  // transform  data for grid
  const getColumDefinitions = () => {
    const columns: GridColDef[] = [
      {
        field: 'orgName',
        headerName: 'ORGANIZATION NAME',
        width: 340,
        renderCell: (params: GridCellParams) => {
          apiRef = params.api
          return (
            <React.Fragment>
              <NavLink
                to={`/organizations/${params.getValue('id')}/${userId}`}
                id='profile-menu-item-engagements'
                exact={true}
              >
                <Edit fontSize='small' />
              </NavLink>
              <NavLink
                to={`/organizations/${params.getValue('id')}/${userId}`}
                id='profile-menu-item-engagements'
                exact={true}
              >
                <span>{params.value}</span>
              </NavLink>
            </React.Fragment>
          )
        },
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 180,
        hide: true,
        valueFormatter: (params: GridValueFormatterParams) => {
          return formatClientId(params.value as number)
        },
      },
      {
        field: 'masterAndClientId',
        headerName: 'ORGANIZATION ID',
        width: 180,
      },
      { field: 'claims', hide: true },
      {
        field: 'appAndClaims',
        headerName: 'ROLE',
        width: 600,
        renderCell: (params: GridCellParams) => {
          const claims = params.getValue('claims') as ApplicationClaim[]
          return <Claims claims={claims} />
        },
      },
      {
        field: 'active',
        headerName: 'ACTIVE',
        width: 130,
        renderCell: (params: GridCellParams) => {
          const checked = params.value as boolean

          return (
            <Checkbox
              disabled={true}
              defaultChecked={checked}
              color='primary'
            />
          )
        },
      },
    ]
    return columns
    // we want to watch search query and re run this
    // eslint-disable-next-line
  }
  const handleExportOrganizations = () => {
    if (apiRef) {
      apiRef.exportDataAsCsv()
    }
  }

  return (
    <Paper className={classes.root} variant='outlined'>
      <AppBar position='static'>
        <Toolbar variant='dense'>
          <div className={classes.ManageUserHeading}>
            Organizations ({orgApps.length}){' '}
          </div>
          <div className={classes.grow} />
          <DownloadButton
            toolTip={'Download Organizations'}
            handleDownLoad={handleExportOrganizations}
          />
        </Toolbar>
      </AppBar>
      <div style={{ height: 'auto', width: '100%' }}>
        <DataGrid
          autoHeight={true}
          rowHeight={40}
          headerHeight={40}
          rows={orgApps}
          columns={getColumDefinitions()}
          pageSize={100}
          density={'compact'}
        />
      </div>
    </Paper>
  )
}
export default UserViewOrgApp
