import { AppBar, makeStyles, Paper, Toolbar, Tooltip } from '@material-ui/core'
import {
  DataGrid,
  GridApi,
  GridCellParams,
  GridColDef,
} from '@material-ui/data-grid'
import React from 'react'
import { DownloadButton } from '../../core/components/Download'
import { BrandedApplicationModel } from '../../core/models'
import { TsaTheme } from '../../core/styles/tsaTheme'
const useStyles = makeStyles((theme: typeof TsaTheme) => ({
  root: {
    margin: theme.spacing(0, 0),
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  grow: {
    flexGrow: 1,
  },
  ManageUserHeading: {
    fontSize: 22,
  },
}))
export interface UserViewBrandedApplicationsProps {
  userApps: BrandedApplicationModel[]
  userId: string
}
const UserViewBrandedApplications = ({
  userApps,
  userId,
}: UserViewBrandedApplicationsProps) => {
  const classes = useStyles()
  var apiRef: GridApi | undefined = undefined
  // transform  data for grid
  const getColumDefinitions = (): GridColDef[] => {
    const columns: GridColDef[] = [
      {
        field: 'identifier',
        headerName: 'IDENTIFIER',
        width: 250,
        hide: false,
        renderCell: (params: GridCellParams) => {
          if (!apiRef) {
            apiRef = params.api
            console.log('SetApiRef From Action RenderCell')
          }
          return (
            <React.Fragment>
              <span>{params.value}</span>
            </React.Fragment>
          )
        },
      },
      {
        field: 'name',
        headerName: 'BRANDED NAME',
        width: 300,
      },

      {
        field: 'launchUri',
        headerName: 'LAUNCH URI',
        width: 300,
        renderCell: (params: GridCellParams) => {
          const anchorName = params.getValue('name')
          return (
            <React.Fragment>
              <Tooltip title={params.value as string} placement='top-start'>
                <a
                  href={params.value ? (params.value as string) : ''}
                  target='_blank'
                  rel='noreferrer'
                >
                  {anchorName}
                </a>
              </Tooltip>
              {/* <span>{params.value}</span> */}
            </React.Fragment>
          )
        },
      },
      {
        field: 'logoUri',
        headerName: 'LOGO URI',
        width: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <React.Fragment>
              <Tooltip title={params.value as string} placement='top-start'>
                <img
                  height={25}
                  alt={params.value as string}
                  src={params.value ? (params.value as string) : ''}
                />
              </Tooltip>
              {/* <span>{params.value}</span> */}
            </React.Fragment>
          )
        },
      },
      {
        field: 'brandedApplicationType',
        headerName: 'TYPE',
        width: 200,
      },
      {
        field: 'description',
        headerName: 'BRANDED DESCRIPTION',
        width: 250,
      },
      {
        field: 'category',
        headerName: 'BRANDED CATEGORY',
        width: 250,
        renderCell: (params: GridCellParams) => {
          return (
            <React.Fragment>
              <Tooltip title={params.value as string} placement='top-start'>
                <span>{params.value}</span>
              </Tooltip>
              {/* <span>{params.value}</span> */}
            </React.Fragment>
          )
        },
      },
    ]
    return columns
  }
  const handleExportApplications = () => {
    if (apiRef) {
      apiRef.exportDataAsCsv()
    }
  }
  return (
    <Paper className={classes.root} variant='outlined'>
      <AppBar position='static'>
        <Toolbar variant='dense'>
          <div className={classes.ManageUserHeading}>
            Authorized Branded Applications ({userApps.length}){' '}
          </div>
          <div className={classes.grow} />
          <DownloadButton
            toolTip={'Download Authorized Branded Applications'}
            handleDownLoad={handleExportApplications}
          />
        </Toolbar>
      </AppBar>
      <div style={{ height: 'auto', width: '100%' }}>
        <DataGrid
          autoHeight={true}
          rowHeight={40}
          headerHeight={40}
          rows={userApps}
          columns={getColumDefinitions()}
          pageSize={100}
          density={'compact'}
        />
      </div>
    </Paper>
  )
}
export default UserViewBrandedApplications
