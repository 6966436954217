import React, { useState } from 'react'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { useDropzone } from 'react-dropzone'
import PublishIcon from '@material-ui/icons/Publish'
import DeleteIcon from '@material-ui/icons/Delete'
import { Toolbar, List, ListItem, ListItemText } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    uploadIcon: {
      color: theme.palette.grey[500],
      height: '4rem',
      width: '4rem',
      display: 'block',
      margin: '0 auto',
    },
    dropZone: {
      color: 'white',
      backgroundColor: theme.palette.grey[500],
      padding: '1rem',
      textAlign: 'center',
      borderRadius: '25px',
      minHeight: 100,
    },
    error: {
      listStyleType: 'none',
      margin: 0,
      padding: 0,
    },
    errorIcon: {
      color: 'red',
    },
    footer: {
      display: 'block',
      margin: '0 auto',
    },
    table: {
      maxHeight: 150,
    },
    content: {
      minHeight: 200,
    },
    delete: {
      color: theme.palette.primary.main,
    },
    filesContainer: {
      minHeight: '3rem',
    },
  })

interface UploadDialogProps extends WithStyles<typeof styles> {
  open: boolean
  onDissmiss: () => void
  onUpload: (files: File[]) => void
  acceptedFiles?: string[]
  uploadButtonText?: string
  maxFiles?: number
  allowMultipleFiles?: boolean

  dropZoneHeader?: React.ReactNode
  dropZoneContent?: React.ReactNode
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onDismiss: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onDismiss: onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions)

const UploadDialog = withStyles(styles)(
  ({
    open,
    acceptedFiles,
    classes,
    onDissmiss,
    onUpload,
    maxFiles = 10,
    allowMultipleFiles = false,
    dropZoneHeader,
    dropZoneContent,
    uploadButtonText = 'Upload Document',
  }: UploadDialogProps) => {
    const handleDrop = (droppedFiles: File[]) => {
      const messages: string[] = []
      setHasMessage(false)

      if (droppedFiles.length === 0) {
        messages.push('Error: Something went wrong')
        setHasMessage(true)
      }

      if (maxFiles > 1) {
        if (
          droppedFiles.length > maxNumberFiles ||
          files.length >= maxNumberFiles
        ) {
          messages.push('Error: Too Many Files')
          setHasMessage(true)
        } else {
          const updated = files.concat(droppedFiles)
          setFiles(updated)
        }
      } else {
        if (droppedFiles.length > maxNumberFiles) {
          messages.push('Error: Too Many Files')
          setHasMessage(true)
        } else {
          files.splice(0)
          const updated = files.concat(droppedFiles)
          setFiles(updated)
        }
      }
      setMessages(messages)
    }

    const handleSubmitFiles = () => {
      onUpload(files)
    }

    const handleClose = () => {
      onDissmiss()
    }

    const getDefaultDropZoneContent = () => {
      return (
        <React.Fragment>
          <h1>Drag and drop file here</h1>
          <p>(or click here to select file)</p>
          <p>File must be saved to your computer to be dragged here</p>
        </React.Fragment>
      )
    }

    const getFileUploadPreview = (files: File[]) => {
      return (
        <div className={classes.table}>
          <List component='div' role='list'>
            {files.map((file, index) => {
              return (
                <ListItem
                  key={index}
                  button
                  divider={allowMultipleFiles}
                  role='listitem'
                >
                  <ListItemText primary={file.name} />
                  <DeleteIcon
                    onClick={() => handleRemoveFile(index)}
                    className={classes.delete}
                  />
                </ListItem>
              )
            })}
          </List>
        </div>
      )
    }

    const handleRemoveFile = (index: number) => {
      const updated = files.map((f) => f)
      updated.splice(index)
      setFiles(updated)
    }

    const getMessages = () => {
      const hasDivider = messages.length > 1
      return (
        <div className={classes.table}>
          <List component='div' role='list'>
            {messages.map((message) => {
              return (
                <ListItem
                  key={message}
                  button
                  divider={hasDivider}
                  role='listitem'
                >
                  <ListItemText primary={message} />
                  <ErrorIcon className={classes.errorIcon} />
                </ListItem>
              )
            })}
          </List>
        </div>
      )
    }

    const [messages, setMessages] = useState<string[]>([])
    const [maxNumberFiles, setMaxNumberFiles] = useState(100)
    const [files, setFiles] = useState<File[]>([])
    const [hasMessage, setHasMessage] = useState<boolean>(false)
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: handleDrop,
      accept: acceptedFiles,
    })

    if (maxFiles && maxFiles !== maxNumberFiles) {
      setMaxNumberFiles(maxFiles)
    }

    const fileUploadDropZoneContent = dropZoneContent
      ? dropZoneContent
      : getDefaultDropZoneContent()

    const fileUploadDropZoneHeader = dropZoneHeader ? (
      dropZoneHeader
    ) : (
      <PublishIcon className={classes.uploadIcon} />
    )

    const hasFiles = files.length > 0
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onDismiss={onDissmiss}>
          <Toolbar>{fileUploadDropZoneHeader}</Toolbar>
        </DialogTitle>
        <DialogContent className={classes.content} dividers>
          <div className={classes.dropZone}>
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {fileUploadDropZoneContent}
              </div>
            </section>
          </div>
        </DialogContent>
        {hasMessage && <DialogContent>{getMessages()}</DialogContent>}
        <div className={classes.filesContainer}>
          {hasFiles && (
            <DialogContent>{getFileUploadPreview(files)}</DialogContent>
          )}
        </div>
        <DialogActions className={classes.footer}>
          <Toolbar>
            <Button
              variant='contained'
              color='primary'
              onClick={handleSubmitFiles}
              disabled={!hasFiles}
            >
              {uploadButtonText}
            </Button>
          </Toolbar>
        </DialogActions>
      </Dialog>
    )
  }
)
export default UploadDialog
