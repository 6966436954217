import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormGroup,
  makeStyles,
  TextField,
} from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { UpdateOrganizationRequest } from '../../core/models'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useOrganizationApplications } from '../../core/hooks/useOrganizationApplications'
import { Loader } from '../../core/components/Loader'
import { LookUpItem, OrganizationApplication } from '../../core/models'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { TsaTheme } from '../../core/styles/tsaTheme'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: typeof TsaTheme) => ({
  root: {
    margin: theme.spacing(2, 0),
    minWidth: 275,
  },
  grow: {
    flexGrow: 1,
  },
}))

export interface OrganizationApplicationsProps {
  organizationId: string
  onSaveApplications: (request: UpdateOrganizationRequest) => void
  canEdit: boolean
  externalDirty: boolean
}

interface FormDefinition {
  applications: number[]
}

// const defaultFormValues: FormDefinition = {
//   applications: [],
// }

const formValidationSchema = Yup.object().shape({
  categories: Yup.array(),
})

export function OrganizationApplications({
  organizationId,
  onSaveApplications,
  canEdit,
  externalDirty,
}: OrganizationApplicationsProps) {
  const classes = useStyles()
  const history = useHistory()
  const { getApplications, requestStatus, applications } =
    useOrganizationApplications()

  const [source, setSource] = useState<LookUpItem[]>([])
  // eslint-disable-next-line
  const [selected, setSelected] = useState<LookUpItem[]>([])

  useEffect(() => {
    getApplications(organizationId, 'External')
  }, [getApplications, organizationId])

  useEffect(() => {
    const transform = (app: OrganizationApplication): LookUpItem => {
      return {
        key: app.id,
        displayName: app.name,
        description: app.name,
        enabled: app.enabled,
        metadata: {},
      }
    }

    const allApps = applications
      .sort((a, b) => {
        // sort by Description
        const nameCompare = String(a.name).localeCompare(b.name)
        return nameCompare
      })
      .map(transform)
    setSource(allApps)

    const selectedApps = applications
      .filter((app) => app.enabled)
      .map(transform)
    setSelected(selectedApps)
  }, [applications])

  const getInitialFormValues = useCallback((): FormDefinition => {
    const apps = applications.filter((app) => app.enabled).map((app) => app.id)
    return {
      applications: apps,
    }
  }, [applications])
  // used for the cancel button
  const GoBack = () => {
    history.goBack()
  }
  const ready = requestStatus === 'Success'
  if (requestStatus === 'Failed' || requestStatus === 'Unauthorized') {
    // we dont want a spinning if  the request fails just return else endless spinner.
    return null
  }
  return (
    <Loader ready={ready}>
      <Formik
        initialValues={getInitialFormValues()}
        validationSchema={formValidationSchema}
        onSubmit={(values, submitProps) => {
          // Will reset the dirty flag
          submitProps.resetForm()
          // will fill in the users to removed in the calling component.
          if (onSaveApplications) {
            return onSaveApplications({
              id: organizationId,
              applications: values.applications,
              toRemove: [],
            })
          }
        }}
      >
        {({
          submitForm,
          isSubmitting,
          errors,
          touched,
          values,
          dirty,
          setFieldValue,
        }) => {
          const handleAutoCompleteChange = (
            field: string,
            items: LookUpItem[]
          ) => {
            const values = items.map((i) => i.key || '').filter((i) => i !== '')
            setFieldValue(field, values)
          }

          const getSelected = () => {
            return source.filter(
              (app) => values.applications.indexOf(app.key as number) !== -1
            )
          }

          return (
            <Card className={classes.root} variant='outlined'>
              <CardHeader></CardHeader>
              <CardContent>
                <Form>
                  <FormGroup>
                    <FormControl>
                      <Autocomplete
                        disabled={!canEdit}
                        multiple
                        id={'applications'}
                        options={source}
                        defaultValue={getSelected()}
                        //value={getSelected()} // this get rid of the controller uncontroller, but cause the post submit  to  render old data.
                        getOptionLabel={(option) => option.description || ''}
                        onChange={(event: any, value: any) =>
                          handleAutoCompleteChange(
                            'applications',
                            value as LookUpItem[]
                          )
                        }
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={'applications'}
                            variant='outlined'
                            label={'Applications'}
                            placeholder=''
                            helperText={
                              touched.applications ? errors.applications : ''
                            }
                            error={
                              touched.applications &&
                              Boolean(errors.applications)
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </FormGroup>
                </Form>
              </CardContent>
              <CardActions>
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  onClick={GoBack}
                >
                  Back
                </Button>
                {canEdit && (
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    disabled={isSubmitting || !(dirty || externalDirty)}
                    onClick={submitForm}
                  >
                    Save
                  </Button>
                )}
              </CardActions>
            </Card>
          )
        }}
      </Formik>
    </Loader>
  )
}
