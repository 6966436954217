import { OidcIdentityContext } from '@rsmus/react-auth'
import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { userIsExternal } from '../services/parseToken'

export const ExternalRoute = (props: RouteProps) => {
  const auth = React.useContext(OidcIdentityContext)
  if (!auth.user) {
    return null
  }

  const isInternal = !userIsExternal(auth.user)
  if (isInternal) {
    return null
  }

  return <Route {...props} />
}
