import React, { useEffect, useState } from 'react'
import { fade } from '@material-ui/core/styles'
import ErrorSharpIcon from '@material-ui/icons/ErrorSharp'
import { green } from '@material-ui/core/colors'
import { Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { TsaTheme } from '../../core/styles/tsaTheme'
import UploadDialog from '../../core/components/FileUpload/UploadDialog'
import SummaryDialog from '../../core/components/FileUpload/UploadSummaryDialog'
import { useFileUpload } from '../../core/hooks/useFileUpload'
const acceptedFileTypes = ['.xltx', '.xltm', '.xlsx', '.xls', '.xlsm']
const useStyles = makeStyles((theme: typeof TsaTheme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addIcon: {
    padding: theme.spacing(0, 2),
    // height: '100%',
    // position: 'absolute',
    // pointerEvents: 'none',
    // display: 'flex',
    alignItems: 'right',
    justifyContent: 'right',
  },
  IconError: {
    color: 'red',
    height: '4rem',
    width: '4rem',
    display: 'block',
    margin: '0 auto',
  },
  headerText: {
    textAlign: 'center',
    display: 'block',
    margin: '0 auto',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  mytoolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  button: {
    marginRight: '5px',
  },
  buttonProgress: {
    color: green[500],
    marginLeft: 10,
  },
}))

export interface HRFeedButtonProps {
  organizationId: string
  canAddEditRsmUser: boolean
  forceUserRefresh?: () => void // callback to refresh list of users on complete of upload
}
export const HRFeedButton = ({
  organizationId,
  canAddEditRsmUser,
  forceUserRefresh,
}: HRFeedButtonProps) => {
  const classes = useStyles()
  const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>()
  const [summaryDialogOpen, setSummaryDialogOpen] = useState<boolean>(false)
  // eslint-disable-next-line
  const [files, setFiles] = useState<File[]>([])
  const { uploadHrFeedFile, uploadRequestStatus, error, results } =
    useFileUpload()

  const handleBeginUpload = () => {
    setUploadDialogOpen(true)
  }

  const handleDismissUpload = () => {
    setUploadDialogOpen(false)
    setSummaryDialogOpen(false)
  }
  const handleSubmitFiles = (droppedFiles: File[]) => {
    // close upload dialog
    setUploadDialogOpen(false)
    setFiles(droppedFiles)
    uploadHrFeedFile(organizationId, droppedFiles)
    //setSummaryDialogOpen(true)
  }
  const handleReUpload = () => {
    setSummaryDialogOpen(false)

    setUploadDialogOpen(true)
  }

  useEffect(() => {
    if (uploadRequestStatus === 'Success') {
      setUploadDialogOpen(false)
      setSummaryDialogOpen(true)
      if (forceUserRefresh) {
        forceUserRefresh()
      }
    } else if (uploadRequestStatus === 'Failed') {
      setUploadDialogOpen(false)
      setSummaryDialogOpen(true)
    }
  }, [uploadRequestStatus]) // eslint-disable-line
  const inProgress = uploadRequestStatus === 'InProgress'
  const getSummaryHeaderError = () => {
    return (
      <div>
        <ErrorSharpIcon className={classes.IconError} />
        <p className={classes.headerText}>{'Users'}</p>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Button
        onClick={handleBeginUpload}
        variant='contained'
        color='primary'
        className={classes.button}
      >
        UPLOAD HR FEED
      </Button>
      {uploadDialogOpen && (
        <UploadDialog
          open={uploadDialogOpen}
          acceptedFiles={acceptedFileTypes}
          maxFiles={1}
          onUpload={handleSubmitFiles}
          onDissmiss={handleDismissUpload}
        />
      )}
      {inProgress && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
      {uploadRequestStatus === 'Failed' && (
        <SummaryDialog
          open={summaryDialogOpen}
          summaryHeader={getSummaryHeaderError()}
          onDissmiss={handleDismissUpload}
          onReUpload={handleReUpload}
          results={results}
          error={error}
        />
      )}
      {uploadRequestStatus === 'Success' && (
        <SummaryDialog
          context={'Users'}
          open={summaryDialogOpen}
          onDissmiss={handleDismissUpload}
          onReUpload={handleReUpload}
          results={results}
        />
      )}
    </React.Fragment>
  )
}
